import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_DEVOLUCIONES = process.env.REACT_APP_URL_DEVOLUCIONES;
  const URL_ARTICULOS_DEVOLUCIONES =
    process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);

  const [value, setValue] = useState("");

  const [notas, setNotas] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");
  const [qrNota, setQrNota] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [articulosNota, setArticulosNota] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");


  const [idsArticulos, setIdsArticulos] = useState([]);

  const [fecha, setFecha] = useState(hoy);

  const [apagado, setApagado] = useState(false);
  const [apagado2, setApagado2] = useState(false);

  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [impuestos, setImpuestos] = useState(0);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idNota: "",
      cantNota: 0,
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      defectos: 0,
      totalPiezas: 0,
      precio: 0,
      total: 0,
      ubicacion: "Almacen",
      tamanoCaja: "",
      pesoCaja: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveDevolucion = async (event) => {
    event.preventDefault();

    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardara la devolucion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Guardar!",
    }).then(async(result) => {
      if (result.isConfirmed) { 


    if(total_cantidad > 0){
    setValidaBoton(false)
    toggleProgreso();
    let totalPedido = inputFields.length;

    try {
      await axios
        .post(
          URL_DEVOLUCIONES,
          {
            fecha,
            clientes: selectedCliente,
            colaboradores: selectedColaborador,
            total_general: (parseFloat(total_general)).toFixed(2),
            observaciones,
            total_cantidad: parseFloat(total_cantidad),
            areas: selectedArea,
            subTotal,
            iva,
            impuestos,
            // surtidos: selectedNota,
            aplicada: "No",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          // PDFOC(data.data._id, data.data.idDevolucion);

          inputFields.map((a) => {
            if (a.articulos != "" && a.totalPiezas != 0) {
              axios
                .post(
                  URL_ARTICULOS_DEVOLUCIONES,
                  {
                    devoluciones: data.data._id,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    defectos: parseFloat(a.defectos),
                    totalPiezas: parseFloat(a.totalPiezas),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total),
                    areas: selectedArea,
                    ubicacion: a.ubicacion,
                    tamanoCaja: a.tamanoCaja,
                    pesoCaja: parseFloat(a.pesoCaja),
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Devolucion",
                          detalle: `${value._id} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", `success`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Devolucion",
                      detalle: `${value._id} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response}`,
      });
      console.log(error);
    }
  }else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Debes capturar la Cantidad!",
    });
  }
}
});
  };



  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        defectos: 0,
        totalPiezas: 0,
        precio: 0,
        total: 0,
        ubicacion: "Almacen",
        tamanoCaja: "",
        pesoCaja: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial =
            (parseFloat(i.cantidad) + parseFloat(i.defectos)) * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp = parseFloat(event.target.value) + parseFloat(i.defectos);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.cantidad = parseFloat(event.target.value);
          i.totalPiezas =
            parseFloat(event.target.value) + parseFloat(i.defectos);
          i.cajas =
            (parseFloat(event.target.value) + parseFloat(i.defectos)) /
            parseFloat(i.piezasPorCaja);
          let totalParcial =
            (parseFloat(event.target.value) + parseFloat(i.defectos)) *
            i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          // i.cajas = 0;
          i.cantidad = 0;
          i.totalPiezas = i.defectos;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputDefectos = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp = parseFloat(event.target.value) + parseFloat(i.cantidad);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.defectos = parseFloat(event.target.value);
          i.totalPiezas =
            parseFloat(event.target.value) + parseFloat(i.cantidad);
          i.cajas =
            (parseFloat(event.target.value) + parseFloat(i.cantidad)) /
            parseFloat(i.piezasPorCaja);
          let totalParcial =
            (parseFloat(event.target.value) + parseFloat(i.cantidad)) *
            i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          // i.cajas = 0;
          i.defectos = 0;
          i.totalPiezas = i.cantidad;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  function BuscaArticulo(id, event) {
    // if (
    //   inputFields.filter((e) => e.articulos == event.target.value).length > 0
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "El modelo ya fue capturado!",
    //     showConfirmButton: false,
    //   });
    //   const newInputFields = inputFields.map((i) => {
    //     if (id === i.id) {
    //       i.articulos = "";
    //     }
    //     return i;
    //   });
    //   setInputFields(newInputFields);
    // } else {
      articulosNota.map((a) => {
        if (a._id == event.target.value) {
          let idArticulo = a.articulos[0]._id;
          let piezasPorCaja = a.articulos[0].piezasPorCaja;
          let precio = a.articulosPedido[0].precio;
          let idNota = a._id;
          let cantNota = a.cantidad;
          let articulosNombre =
            a.articulos[0].codigo + " / " + a.articulos[0].nombre;
          let tamanoCaja = a.articulos[0].tamanoCaja;
          let pesoCaja = a.articulos[0].pesoCaja;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            idNota,
            cantNota,
            articulosNombre,
            tamanoCaja,
            pesoCaja
          );
        }
      });
    // }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    idNota,
    cantNota,
    articulosNombre,
    tamanoCaja,
    pesoCaja
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        i.idNota = idNota;
        i.cantNota = cantNota;
        i.articulosNombre = articulosNombre;
        let totalParcial =
          (parseFloat(i.cantidad) + parseFloat(i.defectos)) * i.precio;
        i.total = totalParcial;
        i.tamanoCaja = tamanoCaja;
        i.pesoCaja = pesoCaja;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.totalPiezas));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (impuestos != 0) {
      setIva(parseFloat(((impuestos * TG) / 100).toFixed(2)));
    } else {
      setIva(0);
    }
    let totTemp = parseFloat(((impuestos * TG) / 100 + TG).toFixed(2));

    setTotalGeneral(totTemp);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };

  function jalaCliente(selectedCliente) {
    setValue(selectedCliente);
    setSelectedCliente(selectedCliente._id);
    setSelectedColaborador(selectedCliente.vendedor[0]._id);
    setApagado2(true);

    setQrNota("");
    setSelectedNota("");
    setArticulosNota([]);
    setIdsArticulos([]);
    setTotalGeneral(0);
    setSubTotal(0);
    setIva(0);
    setTotalCantidad(0);
    setImpuestos(0);

    setInputFields([
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        defectos: 0,
        totalPiezas: 0,
        precio: 0,
        total: 0,
        ubicacion: "Almacen",
        tamanoCaja: "",
        pesoCaja: 0,
      },
    ]);


    axios
    .get(`${URL_ARTICULOS_SURTIDOS}/clienteDevolucion/${selectedCliente._id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosNota = res.data;
      let arrayArticulos = allArticulosNota
      .map((a)=>{
        return{
          id: uuidv4(),
          idNota: a._id,
          cantNota: a.cantidad,
          articulos: a.articulos[0]._id,
          articulosNombre: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
          piezasPorCaja: a.articulos[0].piezasPorCaja,
          cajas: 0,
          cantidad: 0,
          defectos: 0,
          totalPiezas: 0,
          precio: a.articulosPedido[0].precio,
          total: 0,
          ubicacion: "Almacen",
          tamanoCaja: a.articulos[0].tamanoCaja,
          pesoCaja: a.articulos[0].pesoCaja,
        }
      })
      setArticulosNota(allArticulosNota);
      // setSelectedArea(allArticulosNota[0].surtidos[0].areas[0]._id);
      // setSelectedAreaName(allArticulosNota[0].surtidos[0].areas[0].name);
      let result = allArticulosNota.map((a) => a.articulos[0]._id);
      setIdsArticulos(result);
    })
    .catch((err) => {
      console.log(err);
    });


    // axios
    //   .get(`${URL_SURTIDOS}ClienteDevolucion/${selectedCliente._id}`, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allNotas = res.data;
    //     setNotas(allNotas);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }



  function jalaNotas(e) {
    setSelectedNota(e);

    notas.map((a) => {
      if (a._id == e) {
        setImpuestos(a.pedidos[0].impuestos);
      }
    });

    axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosNota = res.data;
        let arrayArticulos = allArticulosNota
        .map((a)=>{
          return{
            id: uuidv4(),
            idNota: a._id,
            cantNota: a.cantidad,
            articulos: a.articulos[0]._id,
            articulosNombre: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
            piezasPorCaja: a.articulos[0].piezasPorCaja,
            cajas: 0,
            cantidad: 0,
            defectos: 0,
            totalPiezas: 0,
            precio: a.articulosPedido[0].precio,
            total: 0,
            ubicacion: "Almacen",
            tamanoCaja: a.articulos[0].tamanoCaja,
            pesoCaja: a.articulos[0].pesoCaja,
          }
        })
        setInputFields(arrayArticulos)
        setArticulosNota(allArticulosNota);
        // setSelectedArea(allArticulosNota[0].surtidos[0].areas[0]._id);
        // setSelectedAreaName(allArticulosNota[0].surtidos[0].areas[0].name);
        let result = allArticulosNota.map((a) => a.articulos[0]._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // function jalaQrNota(event) {
  //   if (event.target.value.length >= 24) {
  //     setQrNota(event.target.value);
  //     setApagado(true);
  //     axios
  //       .get(`${URL_SURTIDOS}/${event.target.value}`, {
  //         headers: {
  //           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         const allArticulosNota = res.data;
  //         if(allArticulosNota.is_active == "Si"){
  //         setSelectedNota(allArticulosNota._id);
  //         setImpuestos(allArticulosNota.impuestos);
  //         setSelectedCliente(allArticulosNota.clientes[0]._id);
  //         setSelectedClienteName(allArticulosNota.clientes[0].nombre_comercial);
  //         setSelectedArea(allArticulosNota.areas[0]._id);
  //         setSelectedAreaName(allArticulosNota.areas[0].name);
  //         setSelectedColaborador(allArticulosNota.colaboradores[0]._id);

  //         axios
  //           .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${allArticulosNota._id}`, {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           })
  //           .then((res) => {
  //             const allArticulosNota = res.data;
  //             setArticulosNota(allArticulosNota);
  //             let result = allArticulosNota.map((a) => a.articulos[0]._id);
  //             setIdsArticulos(result);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           })
  //           }else{
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "La nota esta cancelada",
  //             showConfirmButton: false,
  //           });

  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     setQrNota(event.target.value);
  //   }
  // }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        articulosNota.map((a) => {
          if (a.articulos[0]._id == event.target.value) {
            let idArticulo = a.articulos[0]._id;
            let piezasPorCaja = a.articulos[0].piezasPorCaja;
            let precio = a.articulosPedido[0].precio;
            let idNota = a._id;
            let cantNota = a.cantidad;
            let articulosNombre =
              a.articulos[0].codigo + " / " + a.articulos[0].nombre;
            let tamanoCaja = a.articulos[0].tamanoCaja;
            let pesoCaja = a.articulos[0].pesoCaja;
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              precio,
              idNota,
              cantNota,
              articulosNombre,
              tamanoCaja,
              pesoCaja
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en la nota",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.devoluciones_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Devolucion</h3>
          <Form onSubmit={saveDevolucion}>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                {apagado ? (
                  <Autocomplete
                    disabled
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                    }}
                    onChange={(event, selectedCliente) => {
                      jalaCliente(selectedCliente);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                          label="Escribe las primeras letras"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                ) : (
                  <Autocomplete
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                    }}
                    onChange={(event, selectedCliente) => {
                      jalaCliente(selectedCliente);
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                          label="Escribe las primeras letras"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                )}
              </Col>
              {/* <Col md={2}>
                <Label className="mr-sm-2">Notas</Label>
                {apagado ? (
                  <Input type="select" required disabled>
                    <option value="">Selecciona una nota</option>
                    {notas
                      .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                      .map((a) => {
                        if(a.areas[0]._id == user.areas){
                        return <option value={a._id}>{a.idSurtido}</option>;
                      }})}
                  </Input>
                ) : (
                  <Input
                    type="select"
                    required
                    onChange={(event) => {
                      jalaNotas(event.target.value);
                    }}
                  >
                    <option value="">Selecciona una nota</option>
                    {notas
                      .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
                      .map((a) => {
                        if(a.areas[0]._id == user.areas){
                        return <option value={a._id}>{a.idSurtido}</option>;
                      }})}
                  </Input>
                )}
              </Col> */}
              {/* <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  QR Nota
                </Label>
                {apagado2 ? (
                  <Input
                    type="text"
                    placeholder="QR Nota"
                    value={qrNota}
                    required
                    disabled
                  />
                ) : (
                  <Input
                    type="text"
                    placeholder="QR Nota"
                    value={qrNota}
                    required
                    onChange={(event) => {
                      jalaQrNota(event);
                    }}
                  />
                )}
              </Col> */}
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2"> Almacen </Label>
              <Input
                    type="select"
                    value={selectedArea}
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                            return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Piezas</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas"
                  value={total_cantidad}
                  required
                  disabled
                />
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              {/* <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col> */}
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Venta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Defectuosas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total Piezas</Label>
              </Col>
              {selectedArea == AREAS_CEDIS ? (
                <Col md={1}>
                  <Label className="mr-sm-2">Ubicacion</Label>
                </Col>
              ) : undefined}
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              {/* <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col> */}
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  {/* <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col> */}
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idNota}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosNota
                        .sort((a, b) =>
                          a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.articulos[0].nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantNota"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantNota}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="defectos"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="defectos"
                      value={inputField.defectos}
                      required
                      onChange={(event) =>
                        handleChangeInputDefectos(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="totalPiezas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total Piezas"
                      value={inputField.totalPiezas}
                      required
                      disabled
                    />
                  </Col>
                  {selectedArea == AREAS_CEDIS ? (
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="ubicacion"
                        type="text"
                        placeholder="Ubicacion"
                        value={inputField.ubicacion}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                  ) : undefined}

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                     disabled
                    />
                  </Col>

                  {/* <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col> */}
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoDevoluciones"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesCreate;
