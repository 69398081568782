import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function ListadoNotasCargo() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [notasCargo, setNotasCargo] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState([]);
  const [sucursales, setSucursales] = useState([]);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idNotaCargoEdit, setIdNotaCargoEdit] = useState("");
  const [numeroEdit, setNumeroEdit] = useState("");
  const [fechaEdit, setFechaEdit] = useState("");
  const [idClienteEdit, setIdClienteEdit] = useState("");
  const [importeEdit, setImporteEdit] = useState("");
  const [importeEditViejo, setImporteEditViejo] = useState("");
  const [clienteEdit, setClienteEdit] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [observaciones, setObservaciones] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_SUCURSALES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSucursales = response.data;
        setSucursales(allSucursales);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    axios
      .get(
        `${URL_NOTAS_CARGO}Fechas/${selectedFechaInicio}/${selectedFechaFin}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allNotasCargo = response.data;
        let arrayTabla = allNotasCargo
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              cliente: a.clientes[0].codigo + " / " + a.clientes[0].nombre_comercial,
              idCliente: a.clientes[0]._id,
              importe: a.importe,
              observaciones: a.observaciones,
              sucursal: a.sucursales[0].alias,
              idSucursal: a.sucursales[0]._id,
              numero: a.idNotaCargo,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        setNotasCargo(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = notasCargo.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedCliente == 0 || selectedCliente == c.idCliente)
      ) {
        total = total + c.importe;
        return [
          c.numero,
          c.fecha,
          c.cliente,
          c.sucursal,
          c.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text("Detalle de NotasCargo", 20, 25);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Sucursal", "Observaciones", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleNotasCargo.pdf");
  }

  function excel() {
    const dataExcel = notasCargo.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedCliente == 0 || selectedCliente == c.idCliente)
      ) {
        return {
          Numero: c.numero,
          Fecha: c.fecha,
          Cliente: c.cliente,
          Sucursal: c.sucursal,
          Observaciones: c.observaciones,
          Importe: c.importe,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleNotasCargo-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleNotasCargo`,
        sheetFilter: ["Numero", "Fecha", "Cliente", "Sucursal", "Observaciones", "Importe"],
        sheetHeader: ["Numero", "Fecha", "Cliente", "Sucursal", "Observaciones", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = notasCargo.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedCliente == 0 || selectedCliente == c.idCliente)
      ) {
        total = total + c.importe;
        return [
          c.numero,
          c.fecha,
          c.cliente,
          c.sucursal,
          c.observaciones,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text("Detalle de NotasCargo", 20, 25);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Sucursal", "Observaciones", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Notas Cargo",
          email: mailTo,
          fileName: "ListaNotasCargo.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Notas de Cargo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const editNota = (event) => {
    const URL_NOTAS_CARGO_EDIT = `${process.env.REACT_APP_URL_NOTAS_CARGO}/${idNotaCargoEdit}`;
    event.preventDefault();
    axios
      .all([
        axios.patch(
          URL_NOTAS_CARGO_EDIT,
          {
            importe: parseFloat(importeEdit) - parseFloat(importeEditViejo),
            observaciones,
            fecha:fechaEdit,
            sucursales:sucursal,
            clientes:idClienteEdit
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Nota Cargo",
            detalle: `Fecha: ${fechaEdit} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  const headers = [
    { name: "Numero", field: "numero", sortable: false },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Sucursal", field: "sucursal", sortable: false },
    { name: "Importe", field: "importe", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter((comment) =>
        comment.cliente.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idCliente.includes(selectedCliente)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedCliente,
  ]);


  async function PDFOC(
    numero,
    fecha,
    cliente,
    sucursal,
    importe
  ) {

       
        const doc = new jsPDF({compressPdf: true});
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 85, 30, 50, 15);
        doc.setFontSize(16);
        doc.text(`Nota de Cargo`, 90, 53);
        // doc.text(`___________________`, 80, 100);
        // doc.text(`Firma`, 100, 110);
      
        doc.autoTable({
          head: [
            [
              "Numero",
              "Fecha",
              "Cliente",
              "Sucursal",
              "Importe"
            ],
          ],
          body: [[
            numero,
            fecha,
            cliente,
            sucursal,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(importe)
          ]],
          startY: 60,
        
        });

        doc.save(`NC-${numero}.pdf`);

  }

 function EditNotaCargo(
    _id,
    numero,
    fecha,
    importe,
    idSucursal,
    idCliente,
    cliente,
    observaciones
  ){
    setIdNotaCargoEdit(_id);
    setNumeroEdit(numero);
    setFechaEdit(fecha);
    setIdClienteEdit(idCliente);
    setImporteEdit(importe);
    setImporteEditViejo(importe);
    setClienteEdit(cliente);
    setSucursal(idSucursal)
    setObservaciones(observaciones)
    toggleEdit();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.notasCargo ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    {user.notasCargoCreate ? (
                      <Button
                        size="sm"
                        href="/NotasCargoCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Nota
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Nota
                      </Button>
                    )}
                    <Button
                      size="sm"
                      href="/MenuAdmin"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Notas</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Notas de Cargo</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Cliente</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clientes
                        .sort((a, b) =>
                          a.codigo > b.codigo ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.codigo} {" / "} {a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.importe;
                      return (
                        <tr>
                          <td>{c.numero}</td>
                          <td>{c.fecha}</td>
                          <td> {c.cliente} </td>
                          <td>{c.sucursal}</td>
                          <td>
                            {" "}
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                c.importe
                              )}{" "}
                          </td>
                          <td>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  c.numero,
                                  c.fecha,
                                  c.cliente,
                                  c.sucursal,
                                  c.importe
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="info"
                                onClick={(e) =>
                                  EditNotaCargo(
                                    c._id,
                                    c.numero,
                                    c.fecha,
                                    c.importe,
                                    c.idSucursal,
                                    c.idCliente,
                                    c.cliente,
                                    c.observaciones
                                  )
                                }
                              >
                                <i class="fas fa-edit"></i>
                              </Button>
                        
                      </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> Editar Nota {numeroEdit}</h4>
            </ModalHeader>
            <ModalBody>
              <Row>
              <Col md={6}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fechaEdit}
                    onChange={(e) => {
                      setFechaEdit(e.target.value);
                    }}
                  />
                </Col>
              <Col md={6}>
                    <Label className="mr-sm-2">Sucursal</Label>
                    <Input
                      type="select"
                      value={sucursal}
                      onChange={(e) => {
                        setSucursal(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {sucursales
                        .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                        .map((a) => {
                          if (a.clientes[0]._id == idClienteEdit) {
                            return <option value={a._id}>{a.alias}</option>;
                          }
                        })}
                    </Input>
                  </Col>
              </Row>
              <Row>
   
                <Col md={6}>
                  <Label className="mr-sm-2">Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
              <Col sm={6}>
                    <Label className="mr-sm-2">Importe</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={importeEdit}
                      className={`form-control`}
                      onValueChange={(value, name) => setImporteEdit(value).toFixed(2)}
                    />
                  </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editNota}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoNotasCargo;
