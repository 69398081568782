import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";


function CalificadoraClientes() {
  const { user } = useContext(AuthContext);
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);



  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [value, setValue] = useState("");
  const [plazo, setPlazo] = useState(0);
  const [cargos, setCargos] = useState(0);
  const [saldo, setSaldo] = useState(0);
  const [bonificacion, setBonificacion] = useState(0);
  const [devolucion, setDevolucion] = useState(0);
  const [aTiempo, setATiempo] = useState(0);
  const [aDesTiempo, setADesTiempo] = useState(0);

  const [cargosPorcentaje, setCargosPorcentaje] = useState(0);
  const [saldoPorcentaje, setSaldoPorcentaje] = useState(0);
  const [bonificacionPorcentaje, setBonificacionPorcentaje] = useState(0);
  const [devolucionPorcentaje, setDevolucionPorcentaje] = useState(0);
  const [aTiempoPorcentaje, setATiempoPorcentaje] = useState(0);
  const [aDesTiempoPorcentaje, setADesTiempoPorcentaje] = useState(0);


  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  
  useMemo(() => {
   

    axios
      .get(`${URL_SURTIDOS}CalificadoraCliente/${selectedCliente}/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMovs = response.data;

        let ades = 0
        let atie = 0
        let bon = 0
        let dev = 0

      
        let tVenta = allMovs.map((c) => parseFloat(c.venta));
        let TV = tVenta.reduce((t, total, index) => t + total, 0);
        setCargos(TV);

        allMovs.map((a)=>{
          if(a.concepto=="Abono" || a.concepto.includes("Anticipo")){
          if(a.diffDays > a.plazo){
            return(
            ades = ades + a.importe
            )
          }else{
            return(
            atie = atie + a.importe
            )
          }}else if(a.concepto=="Bonificacion"){
            return(
              bon = bon + a.importe
            )
          } else{
            return(
              dev = dev + a.importe
            )
          }
        })
        setADesTiempo(ades)
        setATiempo(atie)
        setBonificacion(bon)
        setDevolucion(dev)

        let porAdes = ades/TV
        setADesTiempoPorcentaje(porAdes)

        let porAtie = atie/TV
        setATiempoPorcentaje(porAtie)

        let porDev = dev/TV
        setDevolucionPorcentaje(porDev)

        let porBon = bon/TV
        setBonificacionPorcentaje(porBon)

        let porSal = saldo/TV
        setSaldoPorcentaje(porSal)

        let porCar = TV/TV
        setCargosPorcentaje(porCar)

      })
      .catch((err) => {
        console.log(err);
      });

  
  }, [selectedCliente, selectedFechaFin, selectedFechaInicio]);

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.edoCtaClientes ? (
        <div className="card container col-sm-8">
                  <Row>
        <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
        </Row>
          <h3 align="center">Calificadora Clientes</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
             
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                      handleSearchClientes(newInputValue);  // Hacer una nueva búsqueda en cada cambio
                    }}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setPlazo(selectedCliente.plazo)
                        // setCargos(selectedCliente.cargos)
                        setSaldo(selectedCliente.saldo)
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                          label="Escribe las primeras letras"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={1}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
              </Row>
                <br />
                <br />
                <Table>
                  <tr>
                    <th>Venta Total</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cargos)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(cargosPorcentaje)}</td>
                  </tr>
                  <tr>
                    <th>Pagos a Tiempo</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(aTiempo)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(aTiempoPorcentaje)}</td>
                  </tr>
                  <tr>
                    <th>Pagos Atrasados</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(aDesTiempo)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(aDesTiempoPorcentaje)}</td>
                  </tr>
                  <tr>
                    <th>Bonificaciones</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(bonificacion)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(bonificacionPorcentaje)}</td>
                  </tr>
                  <tr>
                    <th>Devoluciones</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(devolucion)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(devolucionPorcentaje)}</td>
                  </tr>
                  <tr>
                    <th>Saldo</th>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(saldoPorcentaje)}</td>
                  </tr>
                </Table>
               
            </FormGroup>
            <br />
          
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default CalificadoraClientes;
