import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col, Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";
import moment from 'moment';

function ListadoCompras() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [compras, setCompras] = useState([]);
  const [articulosOC, setArticulosOC] = useState([]);
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState("");
  const [articulos, setArticulos] = useState([]);


  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);


  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [contenedor, setContenedor] = useState("");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_compra, setNumeroCompra] = useState("");
  const [piezas, setPiezas] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosCompra, setArticulosCompra] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);
  const [totalCaptura, setTotalCaptura] = useState(1000000);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOC: "",
      articulos: "",
      cantidad: 0,
      captura:1000000
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_COMPRAS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idCompras > b.idCompras ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idCompras,
              fecha: a.fecha,
              observaciones: a.observaciones,
              contenedor: a.embarques[0].contenedor,
              totalCantidad: a.total_cantidad.toFixed(2),
              iduser: a.user[0]._id,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              embarque: a.embarques[0].idEmbarques
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCompras(allCompras);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.contenedor,
          a.user,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Contenedor",
          "Recibio",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Compras.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) 
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Contenedor: a.contenedor,
          Recibio: a.user,
          Cantidad: a.totalCantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Contenedor",
          "Recibio",
          "Cantidad",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Contenedor",
          "Recibio",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) 
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.contenedor,
          a.user,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Contenedor",
          "Recibio",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Compras.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    observaciones,
    contenedor,
    numero,
    pais_origen,
    idEdit
  ) {
    setFecha(fecha);
    setObservaciones(observaciones);
    setContenedor(contenedor)
    setNumeroCompra(numero);
    setPaisOrigen(pais_origen);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    totalCantidad,
    observaciones,
    contenedor,
    numero,
    idPDFOC
  ) {
    console.log(idPDFOC)
    await axios
      .get(`${URL_COMPRAS_ACUMULADOS}/compra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCompra = response.data;
        let arrayTabla = allArticulosCompra
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            if(a.cantidad > 0){
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cajas: parseFloat((a.cantidad / a.articulos[0].piezasPorCaja).toFixed(2)),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              precio: a.articulosEmbarques[0].articulosOC[0].precio,
              embarcado: a.articulosEmbarques[0].embarcado,
              pendiente_embarcar: a.articulosEmbarques[0].pendiente_embarcar,
            }
          }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let piezas = dataFinal.length

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

        let total_importe = 0;
        let total_cajas = 0;
        const data = dataFinal.map((a) => {
            total_importe = total_importe + a.precio * a.cantidad;
            total_cajas = total_cajas + a.cajas
            return [
              a.articulos,
              a.cajas,
              a.piezasPorCaja,
              observaciones,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" +
                new Intl.NumberFormat("en-US").format(a.precio * a.cantidad),
            ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Compra # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Contenedor ${contenedor}`, 20, 30);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(totalCantidad)} / Modelos ${piezas}`,
          20,
          35
        );
        doc.autoTable({
          head: [
            ["Articulo", "Cajas", "Piezas por Caja", "Observaciones",  "Cantidad", "Precio", "Total"],
          ],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "Totales",
              new Intl.NumberFormat("en-US").format(total_cajas),
              "",
              "",
              new Intl.NumberFormat("en-US").format(totalCantidad),
              "",
              "$" +
                new Intl.NumberFormat("en-US").format(total_importe),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Compra-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editCompras(event) {
    event.preventDefault();
    const URL_COMPRA_EDIT = `${process.env.REACT_APP_URL_COMPRAS}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_COMPRA_EDIT,
          {
            fecha,
            observaciones,
            contenedor,
            pais_origen,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Compra",
            detalle: `${numero_compra} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Contenedor", field: "contenedor", sortable: true },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Recibio", field: "user", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search)||
          comment.embarque.toString().includes(search)||
          comment.totalCantidad.toString().includes(search)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field && sorting.field != "totalCantidad" && sorting.field != "embarque" && sorting.field != "numero") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "totalCantidad" || sorting.field == "embarque" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "totalCantidad" || sorting.field == "embarque" || sorting.field == "numero")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditArticulos(idC, numero_compra, idOrdenCompra) {
    axios
      .get(`${URL_ARTICULOS_OC}/ordenesCompra/${idOrdenCompra}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFields([
      {
        id: uuidv4(),
        articulosOC: "",
        articulos: "",
        cantidad: 0,
        captura: 1000000
      },
    ]);
    setIdEdit(idC);
    setNumeroCompra(numero_compra);

    axios
      .get(`${URL_INVENTARIOS}/compras/${idC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        let arrayTabla = allArticulos
          .map((a) => {
            return {
              id: a._id,
              articulosOC: a.articulosOC[0]._id,
              articulos: a.articulos[0]._id,
              cantidad: a.cantidad,
              embarcado: a.articulosOC[0].embarcado,
              pendiente_embarcar: a.articulosOC[0].pendiente_embarcar
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosCompra(dataFinal);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleEditArticulos();
  }

  function BuscaArticulo(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        handleChangeInputArticulo(
          id,
          articulosOC,
          idArticulo,
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    articulosOC,
    idArticulo,
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOC = a._id;
        let idArticulo = a.articulos[0]._id;
        handleChangeInputArticuloExistentes(
          id,
          articulosOC,
          idArticulo,
        );
      }
    });
  }

  const handleChangeInputArticuloExistentes = (
    id,
    articulosOC,
    idArticulo,
  ) => {
    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        i.articulosOC = articulosOC;
        i.articulos = idArticulo;
      }
      return i;
    });
    setArticulosCompra(newArticulosCompra);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosCompra(newArticulosCompra);
    ActualizaTotales();
  };

  const handleChangeInputExistentesCantidad = async (id, event, idArticulosOC) => {
    let PR = 0
    let r = 0

    const newArticulosCompra = articulosCompra.map((i) => {
      if (id === i.id) {
        PR = parseFloat(i.pendiente_embarcar) + parseFloat(i.cantidad) - parseFloat(event.target.value)
        i.pendiente_embarcar = PR
        r = parseFloat(i.embarcado) - parseFloat(i.cantidad) + parseFloat(event.target.value)
        i.embarcado = r
      }
      return i
    })

    const newArticulosCompra2 = newArticulosCompra.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i
    });

    const newArticulosCompra3 = newArticulosCompra2.map((i) => {
      if (idArticulosOC == i.articulosOC) {
        i.embarcado = r
        i.pendiente_embarcar = PR
      }
      return i
    })

    setArticulosCompra(newArticulosCompra3);
    ActualizaTotales();
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
  };

  const handleAddFields = () => {
    let TC = totalCaptura +1
    const ultimo = inputFields[inputFields.length-1];
    setTotalCaptura(TC)
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulosOC: ultimo.articulosOC,
        articulos: ultimo.articulos,
        cantidad: 0,
        captura:TC
      },
    ]);
  };

  const saveCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalCompras = inputFields.length + articulosCompra.length
    const URL_COMPRA_EDIT = `${process.env.REACT_APP_URL_COMPRAS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_COMPRA_EDIT,
          {
            total_cantidad: total_cantidad_edit.toFixed(2),
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if(a.articulos != "" && a.cantidad !=0){
              axios.post(
                URL_INVENTARIOS,
                {
                  compras: idEdit,
                  articulos: a.articulos,
                  articulosOC: a.articulosOC,
                  cantidad: parseFloat(a.cantidad),
                  captura: a.captura
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(()=>{
                totalCompras = totalCompras -1
                if(totalCompras == 0 ){
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Compra",
                      detalle: `${numero_compra}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
            } else {
              totalCompras = totalCompras -1
              if(totalCompras == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Compra",
                    detalle: `${numero_compra}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosCompra.map((a) => {
            // Quitar esto
            axios.patch(
              `${URL_ARTICULOS_OC}/${a.articulosOC}`,
              {
                embarcado: a.embarcado,
                pendiente_embarcar: a.pendiente_embarcar,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            axios.patch(
              `${URL_INVENTARIOS}/${a.id}`,
              {
                articulos: a.articulos,
                articulosOC: a.articulosOC,
                cantidad: parseFloat(a.cantidad),
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalCompras = totalCompras -1
              if(totalCompras == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Compra",
                    detalle: `${numero_compra}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  function QRGen(idCompra) {
    axios
      .get(`${URL_INVENTARIOS}/compras/${idCompra}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPiezas = response.data;
        setPiezas(allPiezas);
        const doc = new jsPDF("landscape", "mm", [102,51])
        let alturaLogo = 3;
        let alturaImagen = 5;
        let alturaTexto1 = 18;
        let alturaTexto2 = 23;
        let alturaTexto3 = 28;
        let alturaTexto4 = 33;
        let alturaTexto5 = 40;

        allPiezas
        .sort((a, b) => (a.captura > b.captura ? 1 : -1))
        .map((a) => {
          let codigo = a._id;
          var base64 = qrcode.toDataURL(codigo);
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 43, alturaLogo, 35, 10);
          var img3 = new Image();
          img3.src = base64;
          doc.addImage(img3, "png", 0, alturaImagen, 40, 40);
          doc.setFontSize(11);
          doc.text(`ID ${a._id}`, 38, alturaTexto1);
          doc.text(`Cod ${a.articulos[0].codigo} / Mod ${a.articulos[0].nombre}`, 38, alturaTexto2);
          doc.setFontSize(20);
          doc.text(`Cant. ${a.cantidad}`, 38, alturaTexto5);
          doc.addPage();
        });
        doc.save(`QR.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.menu_compras ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ComprasCreate"
                >
                  Nueva Entrada
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Entrada
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Entrada de Mercancia</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.contenedor}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.totalCantidad)}</td>
                      <td>{a.user}</td>
                      <td>
                        {user.menu_compras ? (
                          <div>
                            {/* <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) => QRGen(a._id)}
                            >
                              <i class="fas fa-qrcode"></i>
                            </Button> */}
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.totalCantidad,
                                  a.observaciones,
                                  a.contenedor,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            {/* <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.observaciones,
                                  a.contenedor,
                                  a.numero,
                                  a.pais_origen,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button> */}

                            {/* <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditArticulos(
                                  a._id,
                                  a.numero,
                                  a.idOrdenesCompra
                                )
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button> */}

                            {/* <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_COMPRAS}
                            /> */}
                            {/* <BajaAltaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_COMPRAS}
                              hijo={`${process.env.REACT_APP_URL_INVENTARIOS}/baja`}
                            /> */}
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Compra # {numero_compra}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Contenedor</Label>
              <Input
                type="text"
                placeholder="Contenedor"
                value={contenedor}
                required
                onChange={(e) => {
                  setContenedor(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCompras}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Articulos Compra # {numero_compra}</h4>
          {/* <h4>
            TOTAL {total_cantidad_edit.toFixed(2)} pzas.
          </h4> */}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          {/* Articulos Existentes*/}

          {articulosCompra.map((ac) => (
            <div key={ac.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={ac.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticuloExistentes(ac.id, event);
                    }}
                    disabled
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                             {a.articulos[0].nombre}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
               

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
min="0"
step="any"
                    placeholder="Cantidad"
                    value={ac.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ac.id, event, ac.articulosOC)
                    }
                  />
                </Col>

                
              </Row>
            </div>
          ))}

          {/* Agregar mas articulos */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={4}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulosOC}
                    required
                    onChange={(event) => {
                      BuscaArticulo(inputField.id, event);
                    }}
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulosOC
                      .sort((a, b) =>
                        a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.articulos[0].nombre}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
               

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
min="0"
step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>

                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(
                  total_cantidad_edit.toFixed(2)
                )}{" "}
                kg. 
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveCompra}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoCompras;
