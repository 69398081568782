import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import BancosCreate from "./BancosCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Badge,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function ListadoBancos() {
  const { user } = useContext(AuthContext);
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_BANCOS_TRASPASOS = process.env.REACT_APP_URL_BANCOS_TRASPASOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const BANCO_DEVOLUCION = process.env.REACT_APP_BANCO_DEVOLUCION;
  const BANCO_BONIFICACION = process.env.REACT_APP_BANCO_BONIFICACION;
  const BANCO_FALTANTES = process.env.REACT_APP_BANCO_FALTANTES;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  const [bancos, setBancos] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [banco, setBanco] = useState("");
  const [cuenta, setCuenta] = useState("");
  const [moneda, setMoneda] = useState("");

  const [idEdit, setIdEdit] = useState();

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  const [bancoNombre, setBancoNombre] = useState("");
  const [bancoOrigen, setBancoOrigen] = useState("");
  const [bancoDestino, setBancoDestino] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [importe, setImporte] = useState(0);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalTraspaso, setModalTraspaso] = useState(false);
  const toggleTraspaso = () => setModalTraspaso(!modalTraspaso);
  const [tipoCambio, setTipoCambio] = useState(1);
  const [monedaOrigen, setMonedaOrigen] = useState("MXN");
  const [monedaDestino, setMonedaDestino] = useState("MXN");
  const [importeDestino, setImporteDestino] = useState(0);


  const [saldo, setSaldo] = useState(0);
  const [saldoInicial, setSaldoInicial] = useState(0);
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [movimientos, setMovimientos] = useState([]);
  const [modalMovs, setModalMovs] = useState(false);
  const toggleMovs = () => setModalMovs(!modalMovs);

  useMemo(() => {
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    let SI = movimientos
      .map((c) => {
        if (selectedFechaInicio) {
          if (selectedFechaInicio == "" || selectedFechaInicio > c.fecha) {
            return c.cargo - c.abono;
          }
        } else {
          return 0;
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let TC = SI.reduce((t, total, index) => t + total, 0);

    setSaldoInicial(TC);
  }, [user, movimientos, selectedFechaInicio]);

  function editCuenta(event) {
    event.preventDefault();
    const URL_BANCOS_EDIT = `${URL_BANCOS}/${idEdit}`;
    axios
      .patch(
        URL_BANCOS_EDIT,
        {
          banco,
          cuenta,
          moneda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Cuenta",
            detalle: `${banco} / ${cuenta}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const saveTraspaso = (event) => {
    event.preventDefault();
    setValidaBoton(false);

    axios
      .post(
        URL_BANCOS_TRASPASOS,
        {
          fecha,
          importe,
          bancoOrigen,
          bancoDestino,
          user: user.id,
          monedaOrigen,
          monedaDestino,
          tipoCambio
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        axios.post(
          `${URL_MOVIMIENTOS_BANCOS}`,
          {
            fecha,
            importe: importeDestino,
            tipo: "Ingreso",
            bancos: bancoDestino,
            bancosTraspasos: data.data._id,
            moneda:monedaDestino,
            tipoCambio: 1,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        axios.post(
          `${URL_MOVIMIENTOS_BANCOS}`,
          {
            fecha,
            importe: -importe,
            tipo: "Egreso",
            bancos: bancoOrigen,
            bancosTraspasos: data.data._id,
            moneda:monedaOrigen,
            tipoCambio: 1,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        axios.post(
          URL_LOGS,
          {
            tipo: "Traspaso Banco",
            detalle: `Fecha: ${fecha} / Origen: ${bancoOrigen} / Destino: ${bancoDestino} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  function jalaInfo(idEdit, banco, cuenta, moneda) {
    setBanco(banco);
    setCuenta(cuenta);
    setMoneda(moneda);
    setIdEdit(idEdit);
    toggle();
  }
  function traspasos(idOrigen, banco, monedaOrigen) {
    setBancoOrigen(idOrigen);
    setBancoNombre(banco);
    setBancoDestino("")
    setMonedaOrigen(monedaOrigen);
    setImporte(0)
    setTipoCambio(1)
    setImporteDestino(0)
    toggleTraspaso();
  }

  function jalaMovimientos(id, abonos, cargos) {
    setMovimientos([])
    setSaldo(abonos - cargos);
    axios
      .get(`${URL_MOVIMIENTOS_BANCOS}banco/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMovimientos = response.data;
        setMovimientos(allMovimientos);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleMovs();
  }

  function jalaBancoDestino(id) {
    setBancoDestino(id);
    bancos.map((a) => {
      if (a._id == id) {
        return setMonedaDestino(a.moneda);
      }
    });
    setImporte(0)
    setImporteDestino(0)
    setTipoCambio(1)
  }


  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container col-sm-11">
        { user.bancos ? (
        <div className="row">
          {
            <div className="col-md-8 col-sm-8">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Cuentas</h3>
                  <Table
                    size="sm"
                    striped
                    borderless
                    className="table-responsive-xl"
                  >
                    <thead>
                      <tr>
                        <th className="tituloTabla">Banco</th>
                        <th className="tituloTabla">Cuenta</th>
                        <th className="tituloTabla">Moneda</th>
                        <th className="tituloTabla">Saldo</th>
                        <th className="tituloTabla">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bancos
                        .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                        .map((a) => {
                          if (
                            a._id != BANCO_DEVOLUCION &&
                            a._id != BANCO_BONIFICACION &&
                            a._id != BANCO_FALTANTES
                          ) {
                            return (
                              <tr>
                                <td>{a.banco}</td>
                                <td>{a.cuenta}</td>
                                <td>{a.moneda}</td>
                                <td>
                                  {"$" +
                                    new Intl.NumberFormat("en-US").format(
                                      a.abonos - a.cargos
                                    )}
                                </td>
                                <td>
                                  <div>
                                    <Button
                                      color="success"
                                      id="Editar"
                                      size="sm"
                                      // disabled
                                      onClick={(e) =>
                                        traspasos(a._id, a.banco, a.moneda)
                                      }
                                    >
                                      <i class="fas fa-dollar-sign"></i>
                                    </Button>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) =>
                                        jalaInfo(
                                          a._id,
                                          a.banco,
                                          a.cuenta,
                                          a.moneda
                                        )
                                      }
                                    >
                                      <i class="far fa-edit"></i>
                                    </Button>

                                    <Button
                                      color="dany"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) =>
                                        jalaMovimientos(
                                          a._id,
                                          a.abonos,
                                          a.cargos
                                        )
                                      }
                                    >
                                      <i class="fas fa-file-alt"></i>
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_BANCOS
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-md-4 col-sm-4">
            <div className="card">
              <div className="card-body">
                <BancosCreate />
              </div>
            </div>
          </div>
        </div>
         ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Cuenta</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Banco</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={banco}
            required
            onChange={(e) => {
              setBanco(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Cuenta</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={cuenta}
            required
            onChange={(e) => {
              setCuenta(e.target.value);
            }}
          />
          <Label for="exampleEmail" className="mr-sm-2">
            Moneda
          </Label>
          <Input
            type="select"
            value={moneda}
            required
            onChange={(e) => {
              setMoneda(e.target.value);
            }}
          >
            <option value="">Seleccina</option>
            <option value="MXN">MXN</option>
            <option value="USD">USD</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCuenta}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalTraspaso} toggle={toggleTraspaso}>
        <ModalHeader toggle={toggleTraspaso}>
          Traspaso de Banco {bancoNombre}
        </ModalHeader>
        <ModalBody>
          <Label>Fecha</Label>
          <Input
            type="date"
            placeholder="Fecha"
            value={fecha}
            required
            onChange={(e) => {
              setFecha(e.target.value);
            }}
          />
          <Label className="mr-sm-2">Banco Destino</Label>
          <Input
            type="select"
            value={bancoDestino}
            onChange={(e) => {
              jalaBancoDestino(e.target.value);
            }}
          >
            <option value="">Selecciona</option>
            {bancos
              .sort((a, b) => (a.banco > b.banco ? 1 : -1))
              .map((a) => {
                if (
                  a._id != bancoOrigen &&
                  a.banco != "Devolucion" &&
                  a.banco != "Bonificacion"
                ) {
                  return (
                    <option value={a._id}>
                      {a.banco} {a.cuenta}
                    </option>
                  );
                }
              })}
          </Input>
          <Label className="mr-sm-2">Importe</Label>
          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={importe}
                            className={`form-control`}
                            onValueChange={(value) =>{
                              setImporte(value)
                              if(monedaOrigen == "MXN" && monedaDestino == "USD"){
                                setImporteDestino((value / tipoCambio).toFixed(2))
                              } else if(monedaOrigen == "USD" && monedaDestino == "MXN"){
                                setImporteDestino((value * tipoCambio).toFixed(2))
                              } else if(monedaOrigen == monedaDestino){
                                setImporteDestino(value)
                              }
                            }}
                          />

          {monedaOrigen != monedaDestino ? (
            <>
              <Label className="mr-sm-2">Tipo de Cambio</Label>
              <Input
                className="col-sm-12"
                type="number"
                value={tipoCambio}
                required
                onChange={(e) => {
                  setTipoCambio(e.target.value);
                  if(monedaOrigen == "MXN" && monedaDestino == "USD"){
                    setImporteDestino((importe / e.target.value).toFixed(2))
                  } else if(monedaOrigen == "USD" && monedaDestino == "MXN"){
                    setImporteDestino((importe * e.target.value).toFixed(2))
                  } else if(monedaOrigen == monedaDestino){
                    setImporteDestino(importe.toFixed(2))
                  }
                }}
              />
                <br />
          <Row>
            <Col md={12}>
          <Label className="mr-sm-2">Importe a agregar en Destino</Label>
          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={importeDestino}
                            className={`form-control`}
                            disabled
                          />
          </Col>
          </Row>
            </>
          ) : undefined}
      
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveTraspaso}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalMovs} toggle={toggleMovs}>
        <ModalHeader toggle={toggleMovs}>
          <h4>Estado Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <ButtonGroup>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="gris"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="gris"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="gris"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button> */}
              </ButtonGroup>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <h4 align="right">
                Saldo
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {"$" + new Intl.NumberFormat("en-US").format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Observaciones</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {"$" +
                        new Intl.NumberFormat("en-US").format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    selectedFechaInicio == "" ||
                    selectedFechaInicio <= c.fecha
                  ) {
                    saldoParcialTabla = saldoParcialTabla - c.cargo + c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.observaciones}</td>
                        <td align="center">{c.numero}</td>
                        <td align="center">
                          {"$" + new Intl.NumberFormat("en-US").format(c.cargo)}
                        </td>
                        <td align="center">
                          {"$" + new Intl.NumberFormat("en-US").format(c.abono)}
                        </td>
                        <td align="center">
                          {"$" +
                            new Intl.NumberFormat("en-US").format(
                              saldoParcialTabla
                            )}
                        </td>
                      </tr>
                    );
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="center">
                    {"$" + new Intl.NumberFormat("en-US").format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="center">
                    {"$" +
                      new Intl.NumberFormat("en-US").format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="center">
                    {"$" +
                      new Intl.NumberFormat("en-US").format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Actual Saldo
                  </td>
                  <td td className="negrita" align="center">
                    {"$" +
                      new Intl.NumberFormat("en-US").format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoBancos;
