import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

import { v4 as uuidv4 } from "uuid";

function ListadoOrdenesComprasViejas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ORDENES_COMPRA_VIEJAS = process.env.REACT_APP_URL_ORDENES_COMPRA_VIEJAS;
  const URL_ARTICULOS_OC_VIEJOS = process.env.REACT_APP_URL_ARTICULOS_OC_VIEJOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [ordenesCompra, setOrdenesCompra] = useState([]);

  const [articulos, setArticulos] = useState([]);
  const [artOC, setArtOC] = useState([]);

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [selectedFechaCompInicio, setSelectedFechaCompInicio] = useState("");
  const [selectedFechaCompFin, setSelectedFechaCompFin] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_cantidad, setTotalCantidad] = useState("");
  const [numero_orden, setNumeroOrden] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");

  const [articulosOrden, setArticulosOrden] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  // const [inputFields, setInputFields] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      precio: 0,
      total: 0,
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [status, setStatus] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA_VIEJAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allOrdenes = response.data;
        // Array para pagination
        let arrayTabla = allOrdenes
          // .sort((a, b) => (a.idOrdenCompra > b.idOrdenCompra ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idOrdenCompra,
              activo: a.is_active,
              fecha: a.fecha,
              plazo: a.plazo,
              fecha_compromiso: a.fecha_compromiso,
              observaciones: a.observaciones,
              total_general: a.total_general,
              proveedores: a.proveedores[0].razon_social,
              idProveedores: a.proveedores[0]._id,
              total_cantidad: a.total_cantidad,
              status: a.status,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setOrdenesCompra(allOrdenes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ListaOrdenesCompra = comments.sort((a, b) =>
    a.idOrdenCompra < b.idOrdenCompra ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.numero,
          a.fecha,
          a.proveedores,
          a.observaciones,
          a.total_cantidad,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Status",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Ordenes-Compra.pdf`);
  }

  function excel() {
    const dataExcel = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Status: a.status,
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedor: a.proveedor,
          Observaciones: a.observaciones,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesComprasViejas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesComprasViejas",
        sheetFilter: [
          "Status",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Status",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaOrdenesCompra.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (status == 0 || status == a.status) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.status,
          a.numero,
          a.fecha,
          a.proveedores,
          a.observaciones,
          a.total_cantidad,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Status",
          "Numero",
          "Fecha",
          "Proveedor",
          "Observaciones",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Compra",
          email: mailTo,
          fileName: "ListadoOrdenesComprasViejas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Compra.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    plazo,
    fecha_compromiso,
    idProveedores,
    total_cantidad,
    total_general,
    observaciones,
    numero_orden,
    idEdit
  ) {
    setFecha(fecha);
    setPlazo(plazo);
    setFechaCompromiso(fecha_compromiso);
    setSelectedProveedorEdit(idProveedores);
    setTotalCantidad(total_cantidad);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroOrden(numero_orden);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    plazo,
    fecha_compromiso,
    idProveedores,
    proveedores,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_OC_VIEJOS}/ordenesCompra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              // id: a._id,
              articulos: a.articulos,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.total,
              // pendiente_recibir: a.pendiente_recibir,
            };
          })
          .filter(function (el) {
            return el != null;
          });
          console.log(arrayTabla)

        let dataFinal = Object.values(arrayTabla);

        let cant = inputFields.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            "$" + new Intl.NumberFormat("en-US").format(a.precio),
            "$" + new Intl.NumberFormat("en-US").format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Orden de Compra # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(total_cantidad)}`,
          20,
          40
        );
        doc.autoTable({
          head: [["Articulo", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`OC-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaCompInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso >= selectedFechaCompInicio
      );
    }

    if (selectedFechaCompFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso <= selectedFechaCompFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    status,
    selectedFechaInicio,
    selectedFechaFin,
    selectedProveedor,
  ]);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
             
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Compra Viejas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>

            {/* 
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompInicio}
                required
                onChange={(e) => {
                  setSelectedFechaCompInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Comp Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaCompFin}
                required
                onChange={(e) => {
                  setSelectedFechaCompFin(e.target.value);
                }}
              />
            </Col> */}

            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={status}
                required
                onChange={(e) => {
                  setStatus(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Abierto">Abierto</option>
                <option value="Cerrado">Cerrado</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.proveedores}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(
                          a.total_cantidad
                        )}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.total_general
                          )}{" "}
                      </td>
                      <td>
                        {user.menu_compras ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.plazo,
                                  a.fecha_compromiso,
                                  a.idProveedores,
                                  a.proveedores,
                                  a.total_cantidad,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>

                            {/* <Baja
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ORDENES_COMPRA_VIEJAS}
                            /> 
                             <BajaAltaMultiple
                              idStatus={a._id}
                              is_active={a.activo}
                              URL_BAJA={process.env.REACT_APP_URL_ORDENES_COMPRA_VIEJAS}
                              hijo={`${process.env.REACT_APP_URL_ARTICULOS_OC_VIEJOS}/baja`}
                            />*/}
                          </div>
                        ) : undefined}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

     <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesComprasViejas;
