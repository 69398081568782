import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import Baja from "../../Baja"

function ListadoGastosGeneral() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS_GENERAL = process.env.REACT_APP_URL_GASTOS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_CATEGORIAS = process.env.REACT_APP_URL_CATEGORIAS;
  const URL_SUB_CATEGORIAS = process.env.REACT_APP_URL_SUB_CATEGORIAS;
  const URL_SOLICITANTES = process.env.REACT_APP_URL_SOLICITANTES;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");


  const [selectedFechaInicio, setSelectedFechaInicio] = useState(endDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [subtotal, setSubtotal] = useState();
  const [subtotalViejo, setSubtotalViejo] = useState();
  const [iva, setIva] = useState();
  const [ivaViejo, setIvaViejo] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState(0);
  const [totalEditViejo, setTotalEditViejo] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const [subCategorias, setSubCategorias] = useState([]);
  const [selectedSubCategoria, setSelectedSubCategoria] = useState("");
  const [solicitantes, setSolicitantes] = useState([]);
  const [selectedSolicitante, setSelectedSolicitante] = useState("");
  const [idBancoEdit, setIdBancoEdit] = useState("");

  const [totalTotal, setTotalTotal] = useState(0);

  const [selectedCategoriaFiltro, setSelectedCategoriaFiltro] = useState("");
  const [selectedSubCategoriaFiltro, setSelectedSubCategoriaFiltro] = useState("");
  const [selectedSolicitanteFiltro, setSelectedSolicitanteFiltro] = useState("");



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
      axios
        .get(
          `${URL_GASTOS_GENERAL}GastosDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allGastos = response.data;
          let arrayTabla = allGastos
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                is_active: a.is_active,
                fecha: a.fecha,
                descripcion: a.descripcion,
                subtotal: a.subtotal,
                iva: a.iva,
                total: a.total,
                categoria: a.categorias[0].name,
                idCategoria: a.categorias[0]._id,
                subCategoria: a.subCategorias[0].name,
                idSubCategoria: a.subCategorias[0]._id,
                solicitante: a.solicitantes[0].name,
                idSolicitante: a.solicitantes[0]._id,
                area: a.areas[0].name,
                idAreas: a.areas[0]._id,
                bancos: a.bancos[0].banco + a.bancos[0].cuenta,
                idBancos: a.bancos[0]._id,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let dataFinal = Object.values(arrayTabla);
          setComments(dataFinal);

          let activos = dataFinal.filter((e) => e.is_active == "Si");
          let can = activos.map((c) => parseFloat(c.total));
          let TCan = can.reduce((t, total, index) => t + total, 0);
          setTotalTotal(TCan);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  useEffect(() => {


    axios
        .get(URL_CATEGORIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allCategorias = res.data;
          setCategorias(allCategorias);
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(URL_SUB_CATEGORIAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allSubCategorias = res.data;
          setSubCategorias(allSubCategorias);
        })
        .catch((err) => {
          console.log(err);
        });
        
      axios
      .get(URL_SOLICITANTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSolicitantes = res.data;
        setSolicitantes(allSolicitantes);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [c.fecha, c.area, c.categoria, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Categoria", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    doc.save("DetalleGastos.pdf");
  }

  function excel() {
    const dataExcel = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        return {
          Fecha: c.fecha,
          Categoria: c.categoria,
          Descripcion: c.descripcion,
          Importe: c.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `DetalleGastos-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `DetalleGastos`,
        sheetFilter: ["Fecha", "Categoria", "Descripcion", "Importe"],
        sheetHeader: ["Fecha", "Categoria", "Descripcion", "Importe"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = comments.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.total;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total);
        return [c.fecha, c.area, c.categoria, c.descripcion, importePDF];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text("Detalle de Gastos", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Categoria", "Descripcion", "Importe"]],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        ["", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Lista de Gastos",
          email: mailTo,
          fileName: "ListaGastos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras la lista de Gastos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const editGasto = (event) => {
    const URL_GASTOS_GENERAL_EDIT = `${process.env.REACT_APP_URL_GASTOS_GENERAL}/${idEdit}`;
    event.preventDefault();
        axios.patch(
          URL_GASTOS_GENERAL_EDIT,
          {
            fecha,
            subtotal: parseFloat(subtotal) - parseFloat(subtotalViejo),
            iva: parseFloat(iva) - parseFloat(ivaViejo),
            total: parseFloat(totalEdit) - parseFloat(totalEditViejo),
            descripcion,
            areas: AREAS_GENERAL,
            categorias:selectedCategoria,
            subCategorias:selectedSubCategoria,
            solicitantes:selectedSolicitante,
            bancos: idBancoEdit
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Edit Gasto",
            detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Categoria", field: "categoria", sortable: true },
    { name: "SubCategoria", field: "subCategoria", sortable: true },
    { name: "Solicitante", field: "solicitante", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    { name: "Importe", field: "total", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.categoria.toLowerCase().includes(search.toLowerCase()) ||
          comment.subCategoria.toLowerCase().includes(search.toLowerCase()) ||
          comment.solicitante.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idAreas.includes(selectedArea)
      );
    }
    if (selectedCategoriaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idCategoria.includes(selectedCategoriaFiltro)
      );
    }
    if (selectedSubCategoriaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idSubCategoria.includes(selectedSubCategoriaFiltro)
      );
    }
    if (selectedSolicitanteFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idSolicitante.includes(selectedSolicitanteFiltro)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedArea,
    selectedCategoriaFiltro,
    selectedSubCategoriaFiltro,
    selectedSolicitanteFiltro,
  ]);

  const calcula = (e) => {
    setSubtotal(e);
    setTotalEdit(parseFloat(e) + parseFloat(iva));
  };

  const calculaIVA = (e) => {
    setIva(e);
    setTotalEdit(parseFloat(subtotal) + parseFloat(e));
  };

  function jalaInfo(id, subtotal, iva, total, categoria, subCategoria, solicitante, descripcion, fecha, idBanco){
    setIdEdit(id)
    setSubtotalViejo(subtotal)
    setSubtotal(subtotal)
    setIvaViejo(iva)
    setIva(iva)
    setTotalEditViejo(total)
    setTotalEdit(total)
    setSelectedCategoria(categoria)
    setSelectedSubCategoria(subCategoria)
    setSelectedSolicitante(solicitante)
    setDescripcion(descripcion)
    setFecha(fecha)
    setIdBancoEdit(idBanco)
    toggleEdit()
  }

  function cancelarGasto(idGasto) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Gasto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_GASTOS_GENERAL}Cancel/${idGasto}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    {user.menu_gastos ? (
                      <Button
                        size="sm"
                        href="/GastosCreate"
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        disabled
                        className="btn btn-success"
                        id="botonListado"
                      >
                        Crear Gasto
                      </Button>
                    )}
                    <Button
                      size="sm"
                      href="/MenuGastos"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Gastos</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Gastos</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <tr>
                    <td></td>
                    <td>
                    <Input
                        bsSize="sm"
                        type="select"
                        required
                        value={selectedCategoriaFiltro}
                        onChange={(e) => setSelectedCategoriaFiltro(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {categorias
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </td>
                    <td>
                    <Input
                        bsSize="sm"
                        type="select"
                        required
                        value={selectedSubCategoriaFiltro}
                        onChange={(e) => setSelectedSubCategoriaFiltro(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {subCategorias
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </td>
                    <td>
                    <Input
                        bsSize="sm"
                        type="select"
                        required
                        value={selectedSolicitanteFiltro}
                        onChange={(e) => setSelectedSolicitanteFiltro(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {solicitantes
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </td>
                  </tr>

                  <tbody>
                    {commentsData.map((c) => {
                      if(c.is_active == "Si"){
                      totalTabla = totalTabla + c.total;
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.categoria}</td>
                          <td>{c.subCategoria}</td>
                          <td>{c.solicitante}</td>
                          <td>{c.descripcion}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.total)}
                          </td>
                          <td>
                          <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => jalaInfo(c._id, c.subtotal, c.iva, c.total, c.idCategoria, c.idSubCategoria, c.idSolicitante, c.descripcion, c.fecha, c.idBancos)}
                            >
                              <i class="far fa-edit"></i>{" "}
                            </Button>
                              <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarGasto(c._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                          </td>
                        </tr>
                      )}else{
                        return (
                          <tr style={{backgroundColor:"#ed8c8c"}}>
                            <td>{c.fecha}</td>
                            <td>{c.categoria}</td>
                            <td>{c.subCategoria}</td>
                            <td>{c.solicitante}</td>
                            <td>{c.descripcion}</td>
                            <td>CANCELADO</td>
                            <td>
                            <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaInfo(c._id, c.subtotal, c.iva, c.total, c.idCategoria, c.idSubCategoria, c.idSolicitante,  c.descripcion, c.fecha, c.idBancos)}
                              >
                                <i class="far fa-edit"></i>{" "}
                              </Button>
                              <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarGasto(c._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                            </td>
                          </tr>
                        )
                      }
                    })}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotal)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
         
          <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> Editar Gasto</h4>
            </ModalHeader>
            <ModalBody>
            <Row>

            <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Fecha
                </Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
            <Col md={6}>
                <Label className="mr-sm-2">Categoria</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    required
                    value={selectedCategoria}
                    onChange={(e) => setSelectedCategoria(e.target.value)}
                  >
                    <option value="0">Selecciona</option>
                    {categorias
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                  </Col>
                  <Col md={6}>
                    <Label className="mr-sm-2">SubCategoria</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedSubCategoria}
                        onChange={(e) => setSelectedSubCategoria(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {subCategorias
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>
                  </Row>
                  <Row>
                <Col md={6}>
                    <Label className="mr-sm-2">Solicito / Autorizo</Label>
                      <Input
                        // bsSize="sm"
                        type="select"
                        required
                        value={selectedSolicitante}
                        onChange={(e) => setSelectedSolicitante(e.target.value)}
                      >
                        <option value="0">Selecciona</option>
                        {solicitantes
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                      </Col>



              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion
                </Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Subtotal
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Subtotal"
                  value={subtotal}
                  required
                  onChange={(e) => calcula(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  IVA
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="IVA"
                  value={iva}
                  required
                  onChange={(e) => calculaIVA(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Total
                </Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder={totalEdit}
                  disabled
                />
              </Col>
            </Row>

            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editGasto}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoGastosGeneral;
