import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ComprasHabCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ORDENES_COMPRA_HAB = process.env.REACT_APP_URL_ORDENES_COMPRA_HAB;
  const URL_COMPRAS_HAB = process.env.REACT_APP_URL_COMPRAS_HAB;
  const URL_HAB_OC = process.env.REACT_APP_URL_HAB_OC;
  const URL_INVENTARIOS_HAB = process.env.REACT_APP_URL_INVENTARIOS_HAB;
  const URL_COMPRAS_ACUMULADOS_HAB = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS_HAB;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ordenesCompraHab, setOrdenesCompraHab] = useState([]);
  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  const [proveedores, setProveedores] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [factura_proveedor, setFacturaProveedor] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  const [pais_origen, setPaisOrigen] = useState("NA");
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [total_dinero, setTotalDinero] = useState(0);

  const [habOC, setHabOC] = useState([]);
  const [fecha_orden_compra, setFechaOrdenCompra] = useState("");
  const [plazo, setPlazo] = useState("");
  const [proveedorOrden, setProveedorOrden] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [total_kilos_orden, setTotalKilosOrden] = useState(0);
  const [total_metros_orden, setTotalMetrosOrden] = useState(0);
  const [total_piezas_orden, setTotalPiezasOrden] = useState(0);

  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const classes = useStyles();

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      habOrden: "",
      insumos: "",
      unidad: "",
      cantidad: 0,
      costo: 0,
      total: 0,
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA_HAB, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allOrdenesCompraHab = res.data;
        setOrdenesCompraHab(allOrdenesCompraHab);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
    .get(`${URL_PROVEEDORES}Tipo/Mex`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const saveCompraNuevaHab = async (event) => {
    event.preventDefault();
    if (
      fecha != "" &&
      observaciones != "" &&
      factura_proveedor != "" &&
      pais_origen != ""
    ) {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos =
        inputFieldsHab.length +
        cantidadAgrupado.length +
        cantidadAgrupado.length;
      try {
        await axios
          .post(
            URL_COMPRAS_HAB,
            {
              fecha,
              ordenesCompraHab: selectedOrdenCompra,
              factura_proveedor,
              observaciones,
              total_kilos,
              total_metros,
              total_piezas,
              user: user.id,
              pais_origen,
              total_costo: total_dinero.toFixed(2),
              saldo: total_dinero.toFixed(2),
              proveedores: proveedorOrden,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFieldsHab.map((a) => {
              if (a.insumos != "" && a.cantidad != 0) {
                axios
                  .post(
                    `${URL_INVENTARIOS_HAB}/InsumosCreate`,
                    {
                      insumos: a.insumos,
                      cantidad: parseFloat(a.cantidad),
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Compra",
                            detalle: `OC ${selectedOrdenCompra}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Compra",
                        detalle: `OC ${selectedOrdenCompra}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
            cantidadAgrupado.map((w) => {
              axios
                .patch(
                  `${URL_HAB_OC}editRecibido/${w.habOrden}`,
                  {
                    cantidad: w.cantidad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Pegamos a Compras Acumulado

            cantidadAgrupado.map((w) => {
              axios
                .post(
                  `${URL_COMPRAS_ACUMULADOS_HAB}`,
                  {
                    comprasHab: data.data._id,
                    insumos: w.insumos,
                    habOC: w.habOrden,
                    cantidad: w.cantidad,
                    ordenesCompraHab: selectedOrdenCompra,
                    fecha,
                    proveedores: proveedorOrden,
                    total: w.total,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Compra",
                          detalle: `OC ${selectedOrdenCompra}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            });

            // Terminamos de post a compras acumulado
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };



  const handleAddFieldsHab = () => {
    const ultimo = inputFieldsHab[inputFieldsHab.length - 1];

    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        habOrden: ultimo.habOrden,
        insumos: ultimo.insumos,
        unidad: ultimo.unidad,
        cantidad: 0,
        costo: ultimo.costo,
        total: 0,
      },
    ]);
  };


  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let PZ = values.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = values.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalDinero(Din);
  };


  const handleChangeInputCantidadHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
    ActualizaTotalesHab();
  };

  

  function BuscaInsumos(id, event) {
    habOC.map((a) => {
      if (a._id == event.target.value) {
        let habOrden = a._id;
        let idInsumos = a.insumos[0]._id;
        let unidadHabiltacion = a.insumos[0].unidad;
        let costo = a.precio;
        handleChangeInputInsumos(
          id,
          habOrden,
          idInsumos,
          unidadHabiltacion,
          costo
        );
      }
    });
  }

  const handleChangeInputInsumos = (
    id,
    habOrden,
    idInsumos,
    unidadHabiltacion,
    costo
  ) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i.habOrden = habOrden;
        i.insumos = idInsumos;
        i.unidad = unidadHabiltacion;
        i.costo = costo;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
    ActualizaTotalesHab();
  };

  function ActualizaTotalesHab() {
    let mts = inputFieldsHab.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFieldsHab.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let PZ = inputFieldsHab.filter((e) => e.unidad == "Piezas");
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TP);

    let totalDin = inputFieldsHab.map((c) => parseFloat(c.total));
    let Din = totalDin.reduce((t, total, index) => t + total, 0);
    setTotalDinero(Din);

    let agrupado = inputFieldsHab.reduce(function (groups, item) {
      const val = item["insumos"];
      groups[val] = groups[val] || {
        insumos: item.insumos,
        cantidad: 0,
        total: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].total += item.total;
      groups[val].id = item.id;
      groups[val].insumos = item.insumos;
      groups[val].unidad = item.unidad;
      groups[val].habOrden = item.habOrden;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
  }

  function jalaOrden(e) {
    setSelectedOrdenCompra(e);
    setHabOC([]);


    setInputFieldsHab([
      {
        id: uuidv4(),
        habOrden: "",
        insumos: "",
        unidad: "",
        cantidad: 0,
        costo: 0,
        total: 0,
      },
    ]);

    ordenesCompraHab.map((a) => {
      if (a._id == e) {
        setFechaOrdenCompra(a.fecha);
        setPlazo(a.plazo);
        setFechaCompromiso(a.fecha_compromiso);
        setTotalKilosOrden(a.total_kilos);
        setTotalMetrosOrden(a.total_metros);
        setTotalPiezasOrden(a.total_piezas);
        setProveedorOrden(a.proveedores[0]._id);
        // if(a.fecha_compromiso < hoy){
        //   Swal.fire({
        //     title: "Estas recibiendo una Orden de Compra vencida!",
        //     text: `Debes de solicitar autorizacion`,
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Si, Recibir!",
        //   })
        // }
      }
    });

    axios
      .get(`${URL_HAB_OC}/ordenesCompraHab/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabOC = res.data;

        let array = allHabOC.map((a)=>{
          return{
            id: uuidv4(),
            habOrden: a._id,
            insumos: a.insumos[0]._id,
            unidad: a.insumos[0].unidad,
            cantidad: a.cantidad,
            costo: a.precio,
            total: a.cantidad * a.precio,
          }
        })

        setInputFieldsHab(array)
        setHabOC(allHabOC);
        

        let mts = array.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = array.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);
    
        let PZ = array.filter((e) => e.unidad == "Piezas");
        let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
        let TP = totalPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TP);
    
        let totalDin = array.map((c) => parseFloat(c.total));
        let Din = totalDin.reduce((t, total, index) => t + total, 0);
        setTotalDinero(Din);
    
        let agrupado = array.reduce(function (groups, item) {
          const val = item["insumos"];
          groups[val] = groups[val] || {
            insumos: item.insumos,
            cantidad: 0,
            total: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].total += item.total;
          groups[val].id = item.id;
          groups[val].insumos = item.insumos;
          groups[val].unidad = item.unidad;
          groups[val].habOrden = item.habOrden;
          return groups;
        }, []);
    
        let CA = Object.values(agrupado);
        setCantidadAgrupado(CA);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_cadenas ? (
        <div className="card container col-10">
          {/* <Row>
            <Col md={2}>
              <Button
                size="sm"
                href="/ListadoComprasHab"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row> */}
          <h3 align="center">Nueva Compra</h3>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Orden de Compra</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedOrdenCompra}
                required
                onChange={(e) => jalaOrden(e.target.value)}
              >
                <option value="0">Selecciona una Orden</option>
                {ordenesCompraHab
                  .sort((a, b) => (a.idOrdenCompraHab < b.idOrdenCompraHab ? 1 : -1))
                  .map((a) => {
                    if (
                      a.status == "Abierto" &&
                      a.is_active == "Si" 
                    ) {
                      return (
                        <option value={a._id}>
                          {a.idOrdenCompraHab} {a.proveedores[0].razon_social}
                        </option>
                      );
                    }
                  })}
              </Input>
            </Col>
            <Col md={3}>
              <Label>Fecha OC</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha_orden_compra}
                required
                disabled
                onChange={(e) => {
                  setFechaOrdenCompra(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Plazo</Label>
              <Input
                type="text"
                placeholder="Plazo"
                value={plazo}
                required
                disabled
                onChange={(e) => {
                  setPlazo(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label>Fecha Compromiso</Label>
              <Input
                type="date"
                placeholder="Fecha Compromiso"
                value={fecha_compromiso}
                required
                disabled
                onChange={(e) => {
                  setFechaCompromiso(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
                // onChange={(e) => {
                //   setFecha(e.target.value);
                // }}
              />
            </Col>
            <Col md={3}>
              <Label>Factura Proveedor</Label>
              <Input
                type="text"
                placeholder="Factura Proveedor"
                value={factura_proveedor}
                required
                onChange={(e) => {
                  setFacturaProveedor(e.target.value);
                }}
              />
            </Col>

            <Col md={3}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)} kg.
                / {new Intl.NumberFormat("en-US").format(total_metros)} mts. /{" "}
                {new Intl.NumberFormat("en-US").format(total_piezas)} pzas. 
              </h4>
            </Col>
          </Row>

          {/* Tabla Articulos */}

            <>
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Insumos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              {inputFieldsHab.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={4}>
                      <Input
                        bsSize="sm"
                        name="insumos"
                        type="select"
                        value={inputField.habOrden}
                        required
                        onChange={(event) => {
                          BuscaInsumos(inputField.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {habOC
                          .sort((a, b) =>
                            a.insumos[0].nombre > b.insumos[0].nombre
                              ? 1
                              : -1
                          )
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.insumos[0].nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="unidad"
                        type="string"
                        value={inputField.unidad}
                        disabled
                      ></Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidadHab(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFieldsHab}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
                <Col md={12}>
                  <h4 id="logoutBoton">
                    TOTALES {new Intl.NumberFormat("en-US").format(total_kilos)}{" "}
                    kg. / {new Intl.NumberFormat("en-US").format(total_metros)}{" "}
                    mts. / {new Intl.NumberFormat("en-US").format(total_piezas)}{" "}
                    pzas.
                  </h4>
                </Col>
              </Row>

              <br />
              {validaBoton ? (
                <>
                  <Row>
                    <Button
                      type="submit"
                      className="btn btn-success"
                      onClick={saveCompraNuevaHab}
                    >
                      Guardar
                    </Button>
                    <Button
                      href="/ListadoComprasHab"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                    <Button
                      href="/ListadoComprasHab"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Row>
                </>
              )}
              <br />
            </>

          {/* Termina Tabla Insumos */}

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ComprasHabCreate;
