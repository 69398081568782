import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function MuestrasSalidaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_MUESTRAS_SALIDA = process.env.REACT_APP_URL_MUESTRAS_SALIDA;
  const URL_ARTICULOS_MS = process.env.REACT_APP_URL_ARTICULOS_MS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_CADENAS = process.env.REACT_APP_AREAS_CADENAS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [total, setTotal] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [value, setValue] = useState("");
  const [text, setText] = useState(false);
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv:0,
      idInv:"",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveMuestrasSalida = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_MUESTRAS_SALIDA,
          {
            fecha,
            areas:selectedArea,
            clientes:value._id,
            observaciones,
            user: user.id,
            total
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            axios.post(
              URL_ARTICULOS_MS,
              {
                muestrasSalida: data.data._id,
                articulos: a.articulos,
                cantidad: parseFloat(a.cantidad),
                inventarios: a.idInv
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          });
          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Muestra Salida",
              detalle: `${selectedArea} ${total}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", `success`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv:0,
        idInv:"",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if(i.cantInv >= event.target.value){
        i[event.target.name] = event.target.value;
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "La cantidad excede tu inventario!",
          showConfirmButton: false,
        })
        i.cantidad = 0
      }}
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };


  function BuscaArticulo(id, event){
    if (
      inventarios.filter((e) => e.idArticulo == event.target.value).length == 1
    ) {
      inventarios.map((a)=>{
        if(a.idArticulo == event.target.value){
          let idArticulo = a.idArticulo
          let cantInv = parseFloat(a.cantidad)
          let idInv = a._id
          handleChangeInputArticuloUnico(id, idArticulo, cantInv, idInv)
        }
      })
    } else
        {
          inventarios.map((a)=>{
            if(a.idArticulo == event.target.value){
              let idArticulo = a.idArticulo
              handleChangeInputArticulo(id, idArticulo)
            }
          })

        }
  }
  const handleChangeInputArticuloUnico = (id, idArticulo, cantInv, idInv) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo
        i.cantInv = parseFloat(cantInv)
        i.idInv = idInv
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  const handleChangeInputArticulo = (id, idArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaUbicacion(id, idInventario){
    if (
      inputFields.filter((e) => e.idInv == idInventario)
        .length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo en esa Ubicacion ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.cantInv = 0;
          i.idInv = ""
        }
        return i;
      });
      setInputFields(newInputFields);
    } else
        {
          inventarios.map((a)=>{
            if(a._id == idInventario){
              let cantInv = parseFloat(a.cantidad)
              let idInv = a._id
              handleChangeInputArticuloUbicacion(id, cantInv, idInv)
            }
          })

        }
  }

  const handleChangeInputArticuloUbicacion = (id, cantInv, idInv) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv)
        i.idInv = idInv
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function ActualizaTotales(){
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function jalaAlmacen(e) {
    setSelectedArea(e);

    axios
      .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);

        let result = allInventarios.map((a) => a.idArticulo);
        setIdsArticulos(result);

        let unicos = allInventarios.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.idArticulo === value.idArticulo
        ))
      )
        setArticulosUnicos(unicos)
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv:0,
        idInv:"",
      },
    ])
  }


  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value))  {

              inventarios.map((a)=>{
                if(a.idArticulo == event.target.value){
                  let idArticulo = a.idArticulo
                  handleChangeInputArticulo(id, idArticulo)
                }
              })      
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu inventario",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  return (
    <>
      <Header />
      <br />
      <br />
      {user.muestrasSalidaCreate ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Salida de Muestra</h3>
          <Form onSubmit={saveMuestrasSalida}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={3}>
              <Label className="mr-sm-2">Cliente</Label>
              <Autocomplete
                size="small"
                value={value}
                onChange={(event, selectedCliente) => {
                  if(selectedCliente){
                        setValue(selectedCliente)}
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                  {option.codigo} {option.nombre_comercial}
                </React.Fragment>
                )}
              />
            </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Almacen Salida</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedArea}
                  required
                  onChange={(e) => {
                    jalaAlmacen(e.target.value);
                  }}
                >
                <option value="0">Almacen Salida</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if(a._id != AREAS_GENERAL){
                      return <option value={a._id}>{a.name}</option>}
                    })}
                </Input>
                
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

              <Row> 
              <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Modelo</Label>
                </Col>
                {
                  selectedArea == AREAS_CEDIS  ?(
                    <Col md={1}>
                    <Label className="mr-sm-2">Ubicacion</Label>
                  </Col>
                  ): undefined
                }
               
                <Col md={1}>
                  <Label className="mr-sm-2">Inventario</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row> 
                    <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                    <Col md={3}>
                      <Input
                        bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.articulos}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Articulo</option>
                        {articulosUnicos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a.idArticulo}>
                                {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                            {
                              selectedArea == AREAS_CEDIS ?(
                                <Col md={1}>
                                <Input
                                  bsSize="sm"
                                  name="ubicacion"
                                  type="select"
                                  value={inputField.idInv}
                                  required
                                  onChange={(event)=>{BuscaUbicacion(inputField.id, event.target.value)}}
                                >
                                  <option value="">Selecciona</option>
                                  {inventarios
                                    .sort((a, b) => (a.ubicacion > b.ubicacion ? 1 : -1))
                                    .map((a) => {
                                      if(a.idArticulo == inputField.articulos){
                                      return (
                                        <option value={a._id}>
                                          {a.ubicacion}
                                        </option>
                                      )}
                                    })}
                                </Input>
                              </Col>
                              ):undefined
                            }
                   

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantInv"
                        type="number"
min="0"
step="any"
                        placeholder="Cantidad"
                        value={inputField.cantInv}
                        required
                        disabled
                      />
                    </Col>
                   

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
min="0"
step="any"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputCantidad(inputField.id, event)
                        }
                      />
                    </Col>

                    
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total} pzas.
              </h4>
              </Col>
              </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoMuestrasSalida"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default MuestrasSalidaCreate;
