import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesDisponibles() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
         
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponible" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.disponible_vendedores ?(
            <Button href="/ListadoDisponibleVendedor" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible Vendedor
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible Vendedor
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.disponible_tmk ?(
            <Button href="/ListadoDisponibleTMK" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible TMK
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible TMK
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>

          <Col md={4} align="center">
          {user.disponible_cadenas ?(
            <Button href="/ListadoDisponibleCadenas" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible Cadenas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible Cadenas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.disponible_ecommerce ?(
            <Button href="/ListadoDisponibleMercado" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible E-Commerce
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible E-Commerce
        </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponibleOutdoors" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible Outdoors
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible Outdoors
        </Button> }
          </Col>
        
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponiblePrepedidos" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible Prepedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible Prepedidos
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesDisponibles;
