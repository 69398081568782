import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";
import ProveedoresInfo from "./ProveedoresInfo"

function TablaProveedoresMex(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES_EDIT = `${process.env.REACT_APP_URL_PROVEEDORES}/${props.idProveedor}`;
  const [nombre_comercial, setNombreComercial] = useState(props.nomcom);
  const [razon_social, setRazonSocial] = useState(props.razsoc);
  const [RFC, setRFC] = useState(props.RFC);
  const [contacto, setContacto] = useState(props.contacto);
  const [telefono, setTelefono] = useState(props.telefono);
  const [email, setEmail] = useState(props.email);
  const [calle, setCalle] = useState(props.calle);
  const [numero_ext, setNumeroExt] = useState(props.numero_ext);
  const [numero_int, setNumeroInt] = useState(props.numero_int);
  const [delegacion, setDelegacion] = useState(props.delegacion);
  const [estado, setEstado] = useState(props.estado);
  const [pais, setPais] = useState(props.pais);
  const [cp, setCP] = useState(props.cp);
  const [colonia, setColonia] = useState(props.colonia);
  const [ciudad, setCiudad] = useState(props.ciudad);
  const [subTipo, setSubTipo] = useState(props.subTipo);
  const [proveedores, setProveedores] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  useEffect(() => {
    axios
      .get(URL_PROVEEDORES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editProveedor(event) {
    event.preventDefault();
    axios
      .patch(
        URL_PROVEEDORES_EDIT,
        {
          nombre_comercial,
          razon_social,
          RFC,
          contacto,
          telefono,
          email,
          calle,
          numero_ext,
          numero_int,
          delegacion,
          estado,
          pais,
          cp,
          colonia,
          ciudad,
          contacto,
          telefono,
          email,
          subTipo
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Crear Proveedor',
            detalle: `Nombre Comercial: ${nombre_comercial}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <td>{props.subTipo}</td>
      <td>{props.nomcom}</td>
      <td>{props.razsoc}</td>
      <td>{props.RFC}</td>
      <td>{props.contacto}</td>
      <td>{props.telefono}</td>
      <td>{props.email}</td>
      {/* <td style={{ textAlign: "center" }}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(props.cargos)}</td>
      <td style={{ textAlign: "center" }}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(props.abonos)}</td>
      <td style={{ textAlign: "center" }}>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(props.saldo)}</td> */}
      <td>
        <span>
          <Button color="success" size="sm" onClick={toggle} id="Detalle">
            <i class="fas fa-file-alt"></i>
          </Button>
        </span>

        <Modal size="sm" isOpen={modal} toggle={toggle}>
  <ModalHeader toggle={toggle}><h4>Informacion del Proveedor</h4></ModalHeader>
          <ModalBody>
            <ProveedoresInfo idProveedor={props.idProveedor} />
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={toggle}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        {user.menu_cadenas  ?(
        <Button color="info" id="Editar" size="sm" onClick={toggleEdit}>
          <i class="far fa-edit"></i>
        </Button>
        ):(
        <Button color="info" id="Editar" size="sm" disabled>
          <i class="far fa-edit"></i>
        </Button>
        )}

        <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}>
            <h4>Editar Informacion de {props.nomcom}</h4>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={4}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Contacto</Label>
                <Input
                  type="text"
                  placeholder="Contacto"
                  value={contacto}
                  required
                  onChange={(e) => {
                    setContacto(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Telefono</Label>
                <Input
                  type="number"
min="0"
step="any"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
            </Row>
             <Row>
              <Col md={4}>
              <Label>Tipo</Label>
                  <Input
                    type="select"
                    value={subTipo}
                    required
                    onChange={(e) => {
                      setSubTipo(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Compras Habilitacion">Compras Habilitacion</option>
                    <option value="Maquilero">Maquilero</option>
                    </Input>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={editProveedor}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
        {user.menu_cadenas  ?(
        <Baja
          idStatus={props._id}
          is_active={props.is_active}
          URL_BAJA={process.env.REACT_APP_URL_PROVEEDORES}
        />
        ):undefined}
      </td>
    </>
  );
}

export default TablaProveedoresMex;
