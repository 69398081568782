import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";

function ListadoUbicaciones2() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;

  const [inventarios, setInventarios] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [selectedUbicacion, setSelectedUbicacion] = useState("");
  const [total_cantidad_anterior, setTotalCantidadAnterior] = useState(0);
  const [total_cantidad_nueva, setTotalCantidadNueva] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [validaBoton, setValidaBoton] = useState(true);

  useMemo(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

      if(selectedUbicacion){
        axios
        .get(`${URL_INVENTARIOS}BuscaUbicaciones/${selectedUbicacion}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allDisponible = response.data;
          setComments(allDisponible);
          setInventarios(allDisponible);
  
          let cant = allDisponible.map((c) => parseFloat(c.cantidadAnterior));
          let TC = cant.reduce((t, total, index) => t + total, 0);
          setTotalCantidadAnterior(TC);
          setTotalCantidadNueva(TC);
        })
        .catch((err) => {
          console.log(err);
        });
      }
  }, [selectedUbicacion]);



  function PDFTabla() {
    let total_cantidad = 0;
    let total_cajas = 0;
    const data = inventarios.map((a) => {
      total_cantidad = total_cantidad + a.cantidad;
      total_cajas = total_cajas + a.cantidad / a.piezasPorCaja;
      return [
        a.codigo,
        a.nombre,
        a.piezasPorCaja,
        new Intl.NumberFormat("en-US").format(
          (a.cantidad / a.piezasPorCaja).toFixed(2)
        ),
        new Intl.NumberFormat("en-US").format(a.cantidad),
        a.area,
        a.ubicacion,
      ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ubicaciones`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "PxC",
          "Cajas",
          "Cantidad",
          "Almacen",
          "Ubicacion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_cajas.toFixed(2)),
          new Intl.NumberFormat("en-US").format(total_cantidad),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Ubicaciones.pdf`);
  }

  function excel() {
    const dataExcel = inventarios.map((a) => {
      return {
        Codigo: a.codigo,
        Nombre: a.nombre,
        PiezasPorCaja: a.piezasPorCaja,
        Cajas: (a.cantidad / a.piezasPorCaja).toFixed(2),
        Cantidad: a.cantidad,
        Almacen: a.area,
        Ubicacion: a.ubicacion,
      };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoUbicaciones";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoUbicaciones",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "Cajas",
          "Cantidad",
          "Almacen",
          "Ubicacion",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "PiezasPorCaja",
          "Cajas",
          "Cantidad",
          "Almacen",
          "Ubicacion",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_cantidad = 0;
    let total_cajas = 0;
    const data = inventarios.map((a) => {
      total_cantidad = total_cantidad + a.cantidad;
      total_cajas = total_cajas + a.cantidad / a.piezasPorCaja;
      return [
        a.codigo,
        a.nombre,
        a.piezasPorCaja,
        new Intl.NumberFormat("en-US").format(
          (a.cantidad / a.piezasPorCaja).toFixed(2)
        ),
        new Intl.NumberFormat("en-US").format(a.cantidad),
        a.area,
        a.ubicacion,
      ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ubicaciones`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "PxC",
          "Cajas",
          "Cantidad",
          "Almacen",
          "Ubicacion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_cajas.toFixed(2)),
          new Intl.NumberFormat("en-US").format(total_cantidad),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Seguimiento",
          email: mailTo,
          fileName: "ListadoUbicaciones.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Piezas por Caja", field: "piezasPorCaja", sortable: true },
    { name: "Cajas", field: "cajas", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    // { name: "Almacen", field: "area", sortable: true },
    { name: "Ubicacion", field: "ubicacion", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.ubicacion.toLowerCase().includes(search.toLowerCase()) ||
          comment.piezasPorCaja.toString().includes(search) ||
          comment.cajas.toString().includes(search) ||
          comment.cantidad.toString().includes(search)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "piezasPorCaja" &&
      sorting.field != "cajas"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "piezasPorCaja" ||
        sorting.field == "cajas")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "piezasPorCaja" ||
        sorting.field == "cajas")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.ubicaciones ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Ubicaciones en CEDIS</h3>
          <div className="row">
            <div className="col-md-2">
              <Label>Ubicacion</Label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Input
                bsSize="sm"
                type="text"
                value={selectedUbicacion}
                onChange={(e) => {
                  setSelectedUbicacion(e.target.value);
                  // setCurrentPage(1)
                }}
              ></Input>
            </div>
          </div>
          <br />
          {inventarios.length > 0 ? (
            <>
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />

                <tbody>
                  {inventarios.map((ao) => {
                    return (
                      <tr key={ao._id}>
                        <td>{ao.codigo}</td>
                        <td>{ao.nombre}</td>
                        <td>{ao.piezasPorCaja}</td>
                        <td>{ao.cajas.toFixed(2)}</td>
                        <td>{ao.cantidadNueva}</td>
                        <td>{ao.ubicacion}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td td className="negrita" align="center">
                      Total
                    </td>
                    <td td className="negrita">
                      {total_cantidad_nueva}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <h4>No se encontraron Movimientos....</h4>
          )}
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default ListadoUbicaciones2;
