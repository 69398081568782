import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function EstadoCuenta() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_TMK = process.env.REACT_APP_AREAS_TMK;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_SUCURSALES = process.env.REACT_APP_URL_SUCURSALES;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");


  const [grupos, setGrupos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("6230c9f7a616d56b0c8d8436");
  const [colaboradores, setColaboradores] = useState([]);
  const [mailTo, setMailTo] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldo, setSaldo] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);
  const [modalMail3, setModalMail3] = useState(false);
  const toggleMail3 = () => setModalMail3(!modalMail3);
  const [modalMail4, setModalMail4] = useState(false);
  const toggleMail4 = () => setModalMail4(!modalMail4);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalAgrupado, setModalAgrupado] = useState(false);
  const toggleAgrupado = () => setModalAgrupado(!modalAgrupado);

  const [modalRelacionado, setModalRelacionado] = useState(false);
  const toggleRelacionado = () => setModalRelacionado(!modalRelacionado);
  const [saldoFiltroRel, setSaldoFiltroRel] = useState("Pendientes");

  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const toggleWhatsApp = () => setModalWhatsApp(!modalWhatsApp);
  const [modalWhatsAppAgrupado, setModalWhatsAppAgrupado] = useState(false);
  const toggleWhatsAppAgrupado = () => setModalWhatsAppAgrupado(!modalWhatsAppAgrupado);
  const [telefono, setTelefono] = useState("");

  const [activo, setActivo] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [grupo, setGrupo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [movimientos, setMovimientos] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [saldoInicial, setSaldoInicial] = useState(0);
  const [plazo, setPlazo] = useState(0);

  const [totalTotalCargos, setTotalTotalCargos] = useState(0);
  const [totalTotalAbonos, setTotalTotalAbonos] = useState(0);
  const [totalTotalSaldo, setTotalTotalSaldo] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CLIENTES}Departamento/${selectedDepartamento}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
          .map((a) => {
            if (user.areas == AREAS_GENERAL) {
              return {
                _id: a._id,
                activo: a.is_active,
                codigo: a.codigo,
                nombre_comercial: a.nombre_comercial,
                razon_social: a.razon_social,
                grupo: a.grupos[0].name,
                idGrupo: a.grupos[0]._id,
                departamento: a.departamentosClientes[0].name,
                idDepartamento: a.departamentosClientes[0]._id,
                vendedor: a.vendedor[0].nombre + " " + a.vendedor[0].apellido,
                idVendedor: a.vendedor[0]._id,
                area: a.areas[0].name,
                idArea: a.areas[0]._id,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
                cliente: a.codigo + " / " + a.nombre_comercial,
                plazo: a.plazo,
                telefono: a.telefono,
              };
            } else if (user.areas == AREAS_TMK) {
              if (a.areas[0]._id == AREAS_TMK)
                return {
                  _id: a._id,
                  activo: a.is_active,
                  codigo: a.codigo,
                  nombre_comercial: a.nombre_comercial,
                  razon_social: a.razon_social,
                  grupo: a.grupos[0].name,
                  idGrupo: a.grupos[0]._id,
                  departamento: a.departamentosClientes[0].name,
                  idDepartamento: a.departamentosClientes[0]._id,
                  vendedor: a.vendedor[0].nombre + " " + a.vendedor[0].apellido,
                  idVendedor: a.vendedor[0]._id,
                  area: a.areas[0].name,
                  idArea: a.areas[0]._id,
                  cargos: parseFloat(a.cargos),
                  abonos: parseFloat(a.abonos),
                  saldo: parseFloat(a.saldo),
                  cliente: a.codigo + " / " + a.nombre_comercial,
                  plazo: a.plazo,
                  telefono: a.telefono,
                };
            } else if (user.areas == a.areas[0]._id) {
              return {
                _id: a._id,
                activo: a.is_active,
                codigo: a.codigo,
                nombre_comercial: a.nombre_comercial,
                razon_social: a.razon_social,
                grupo: a.grupos[0].name,
                idGrupo: a.grupos[0]._id,
                departamento: a.departamentosClientes[0].name,
                idDepartamento: a.departamentosClientes[0]._id,
                vendedor: a.vendedor[0].nombre + " " + a.vendedor[0].apellido,
                idVendedor: a.vendedor[0]._id,
                area: a.areas[0].name,
                idArea: a.areas[0]._id,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
                cliente: a.codigo + " / " + a.nombre_comercial,
                plazo: a.plazo,
                telefono: a.telefono,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);

        let car = dataFinal.map((c) => parseFloat(c.cargos));
        let TCar = car.reduce((t, cargos, index) => t + cargos, 0);
        setTotalTotalCargos(TCar);

        let abo = dataFinal.map((c) => parseFloat(c.abonos));
        let TAbo = abo.reduce((t, abonos, index) => t + abonos, 0);
        setTotalTotalAbonos(TAbo);

        let sal = dataFinal.map((c) => parseFloat(c.saldo));
        let TSal = sal.reduce((t, saldo, index) => t + saldo, 0);
        setTotalTotalSaldo(TSal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

  
    axios
      .get(URL_SUCURSALES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSucursales = response.data;
        setSucursales(allSucursales);
      })
      .catch((err) => {
        console.log(err);
      });

    let SI = movimientos
      .map((c) => {
        if (selectedFechaInicio) {
          if (
            (selectedSucursal == "" || selectedSucursal == c.idSucursal) &&
            (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ) {
            return c.cargo - c.abono;
          }
        } else {
          return 0;
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let TC = SI.reduce((t, total, index) => t + total, 0);

    setSaldoInicial(TC);
  }, [user, movimientos, selectedFechaInicio, selectedSucursal, selectedDepartamento]);


  function PDFTabla() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.area,
          a.grupo,
          a.departamento,
          a.vendedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Cliente",
          "Almacen",
          "Grupo",
          "Departamento",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuenta.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Codigo: a.codigo,
          Cliente: a.nombre_comercial,
          Almacen: a.area,
          Grupo: a.grupo,
          Departamento: a.departamento,
          Vendedor: a.vendedor,
          Cargos: a.cargos,
          Abonos: a.abonos,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Codigo",
          "Cliente",
          "Almacen",
          "Grupo",
          "Departamento",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Codigo",
          "Cliente",
          "Almacen",
          "Grupo",
          "Departamento",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = comments.map((a) => {
      if (
        (grupo == 0 || grupo == a.idGrupo) &&
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.area,
          a.grupo,
          a.departamento,
          a.vendedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Estado de Cuenta`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Cliente",
          "Almacen",
          "Grupo",
          "Departamento",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Grupo", field: "grupo", sortable: true },
    { name: "Departamento", field: "departamento", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.grupo.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search) ||
          comment.abonos.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }
    if (grupo) {
      computedComments = computedComments.filter((e) =>
        e.idGrupo.includes(grupo)
      );
    }
    if (vendedor) {
      computedComments = computedComments.filter((e) =>
        e.idVendedor.includes(vendedor)
      );
    }
    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cargos" &&
      sorting.field != "abonos" &&
      sorting.field != "saldo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    vendedor,
    grupo,
    activo,
    selectedDepartamento,
  ]);

  async function jalaMov(id, cliente, plazo, saldo, telefono) {
    setTelefono("")
    await axios
      .get(`${URL_ESTADO_CUENTA}/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setSelectedSucursal("");
    setPlazo(plazo);
    setTelefono(telefono);
    setSelectedFechaInicio(startDate);
    toggle();
  }

  async function jalaMovAgrupado(id, cliente, plazo, saldo, telefono) {
    setTelefono("")
    await axios
      .get(`${URL_ESTADO_CUENTA}Agrupado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    setTelefono(telefono);
    setSelectedFechaInicio(startDate);
    toggleAgrupado();
  }

  async function jalaMovRel(id, cliente, plazo, saldo) {
    setTelefono("")
    await axios
      .get(`${URL_ESTADO_CUENTA}Relacionado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    setTelefono(telefono);
    setSelectedFechaInicio(startDate);
    toggleRelacionado();
  }

  // PDF Mail Excel Cliente
  // let saldoParcial = saldoInicial;
  // let totalCargosEdoCte = 0;
  // let totalAbonosEdoCte = 0;
  // let totalSaldoEdoCte = 0;

  function PDFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.sucursal,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
  }

  function PDFOCWhatsApp() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.sucursal,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });

    var att = doc.output("arraybuffer");
    let params = new URLSearchParams({
      number: telefono,
      message:`Hola, Aquí te enviamos el Estado de Cuenta`,
      fileName:`EstadoCuenta.pdf`,
      mimetype: "application/pdf",
    })

    axios
      .post(
        `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
        att,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
            "Content-Type": "application/octet-stream",
          },
        }
      )
    .then(() => {
      Swal.fire("Good job!", "Enviado con exito", "success");
      setModalWhatsApp(false)
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    });

  }

  function PDFOCAgrupadoWhatsApp() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });

    var att = doc.output("arraybuffer");
    let params = new URLSearchParams({
      number: telefono,
      message:`Hola, Aquí te enviamos el Estado de Cuenta`,
      fileName:`EstadoCuenta.pdf`,
      mimetype: "application/pdf",
    })

    axios
      .post(
        `${URL_WHATSAPP}SendPDFFront?${params.toString()}`,
        att,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
            "Content-Type": "application/octet-stream",
          },
        }
      )
    .then(() => {
      Swal.fire("Good job!", "Enviado con exito", "success");
      setModalWhatsApp(false)
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    });

  }

  function emailFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.sucursal,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedSucursal == 0 || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Sucursal: a.sucursal,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });

    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Sucursal",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function PDFOCAgrupado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
  }

  function emailFOCAgrupado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const filaSaldoAnterior = [
      "NA",
      "NA",
      "Saldo Anterior",
      "NA",
      "NA",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial)
    ];

    const data = movimientos.map((a) => {
      if (
        (selectedSucursal == "" || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });

    if (selectedFechaInicio) {
      data.unshift(filaSaldoAnterior);
    }

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 150, 25, 35, 15);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail3();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOCAgrupado() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedSucursal == 0 || selectedSucursal == a.idSucursal) &&
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });

    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function excelFOCRelacionado() {

      const dataExcel = movimientos.map((a) => {
        if (
          a.saldo > 1
        ) {
          return {
            Fecha: a.fecha,
            Movimiento: a.movimiento,
            Numero: a.numero,
            Cargo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            Abono:new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
          };
        }
      });

      const dataExportExcel = Object.values(dataExcel);
      const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cargo",
          "Abono",
        ],
      },
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function PDFOCRelacionado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;


    //if(saldoFiltroRel == "Pendientes"){
      const data = movimientos.map((a) => {
        if (
         a.saldo > 1
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      // doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Movimiento",
            "Numero",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
   // }
  }

  function emailFOCRelacionado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;


      const data = movimientos.map((a) => {
        if (
          a.saldo > 1
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });

      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      // doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Movimiento",
            "Numero",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail4();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  let totalCargosTabla = 0;
  let totalAbonosTabla = 0;
  let totalSaldoTabla = 0;

  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;

  let saldoParcialTablaAgrupado = saldoInicial;
  let totalCargosEdoAgrupado = 0;
  let totalAbonosEdoAgrupado = 0;
  let totalSaldoEdoAgrupado = 0;

  let saldoParcialTablaRelacionado = saldoInicial;
  let totalCargosEdoRelacionado = 0;
  let totalAbonosEdoRelacionado = 0;
  let totalSaldoEdoRelacionado = 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const secondDate = new Date(hoy);
  let saldoVencido =0
  let saldoVencidoRelacionado =0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.edoCtaClientes ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <h3 align="center">Estado de Cuenta</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label>Grupos</Label>
              <Input
                bsSize="sm"
                type="select"
                value={grupo}
                onChange={(e) => {
                  setGrupo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {grupos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            {user.areas == AREAS_GENERAL ? (
              <Col md={2}>
                <Label>Departamento</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedDepartamento}
                  onChange={(e) => {
                    setSelectedDepartamento(e.target.value);
                    setCurrentPage(1);
                    setTotalTotalCargos(0);
                    setTotalTotalAbonos(0);
                    setTotalTotalSaldo(0);
                  }}
                >
                  {departamentos
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            ) : undefined}

            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={vendedor}
                onChange={(e) => {
                  setVendedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalCargosTabla = totalCargosTabla + parseFloat(p.cargos);
                totalAbonosTabla = totalAbonosTabla + parseFloat(p.abonos);
                totalSaldoTabla = totalSaldoTabla + parseFloat(p.saldo);
                {
                  return (
                    <tr>
                      <td>{p.codigo}</td>
                      <td> {p.nombre_comercial}</td>
                      <td>{p.area}</td>
                      <td>{p.grupo}</td>
                      <td>{p.departamento}</td>
                      <td>{p.vendedor}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.cargos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.abonos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(p.saldo)}
                      </td>
                      <td>
                        <Button
                          color="info"
                          size="sm"
                          onClick={(e) =>
                            jalaMov(p._id, p.cliente, p.plazo, p.saldo, p.telefono)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>

                        <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            jalaMovAgrupado(p._id, p.cliente, p.plazo, p.saldo, p.telefono)
                          }
                          id="Detalle"
                        >
                          <i class="far fa-newspaper"></i>
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={(e) =>
                            jalaMovRel(p._id, p.cliente, p.plazo, p.saldo)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSaldoTabla)}
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalCargos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalAbonos)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalSaldo)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="success"
                  onClick={toggleWhatsApp}
                > WA <i class="fab fa-whatsapp"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
            </Row>
            <Row>
            <Col md={2}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Sucursal</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedSucursal}
                    onChange={(e) => {
                      setSelectedSucursal(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {sucursales
                      .sort((a, b) => (a.alias > b.alias ? 1 : -1))
                      .map((a) => {
                        if (a.clientes[0]._id == idCliente) {
                          return <option value={a._id}>{a.alias}</option>;
                        }
                      })}
                  </Input>
                </Col>
              </Row>
            </Col>

            <Col md={2}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla" align="left">
                    Sucursal
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedSucursal == "" ||
                      selectedSucursal == c.idSucursal) &&
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.ceil((secondDate - firstDate) / oneDay))
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 0 &&  diffDays > plazo){
                      saldoVencido = 0
                      saldoVencido = saldoVencido + c.saldo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.sucursal}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                saldoParcialTabla
                              )}
                          </td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.sucursal}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.numero}</td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              saldoParcialTabla
                            )}
                        </td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Vencido
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



      <Modal size="xxl" isOpen={modalAgrupado} toggle={toggleAgrupado}>
        <ModalHeader toggle={toggleAgrupado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOCAgrupado}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="success"
                  onClick={toggleWhatsAppAgrupado}
                > WA <i class="fab fa-whatsapp"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail3}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOCAgrupado}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
</Row>
<Row>
                <Col md={1}>
                  </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
                </Col>

                <Col md={1}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>

          

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedSucursal == "" ||
                      selectedSucursal == c.idSucursal) &&
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTablaAgrupado = saldoParcialTablaAgrupado + c.cargo - c.abono;
                    totalCargosEdoAgrupado = totalCargosEdoAgrupado + c.cargo;
                    totalAbonosEdoAgrupado = totalAbonosEdoAgrupado + c.abono;
                    totalSaldoEdoAgrupado = totalCargosEdoAgrupado - totalAbonosEdoAgrupado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.ceil((secondDate - firstDate) / oneDay))
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 0 &&  diffDays > plazo){
                      saldoVencido = 0
                      saldoVencido = saldoVencido + c.saldo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                                saldoParcialTablaAgrupado
                              )}
                          </td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.numero}</td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.cargo)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.abono)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              saldoParcialTablaAgrupado
                            )}
                        </td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Anterior
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargosEdoAgrupado)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonosEdoAgrupado)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Vencido
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td td className="negrita">
                    Saldo Actual
                  </td>
                  <td td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleAgrupado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalRelacionado} toggle={toggleRelacionado}>
        <ModalHeader toggle={toggleRelacionado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOCRelacionado}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail4}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOCRelacionado}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={8}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>

          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                  <th className="tituloTabla">Saldo Total</th>
                </tr>
              </thead>
              <tbody>
                 {movimientos.map((c) => {
                  if (
                    (c.saldo > 1)
                  ) {
                    saldoParcialTablaRelacionado = saldoParcialTablaRelacionado + c.cargo - c.abono;
                    totalCargosEdoRelacionado = totalCargosEdoRelacionado + c.cargo;
                    totalAbonosEdoRelacionado = totalAbonosEdoRelacionado + c.abono;
                    totalSaldoEdoRelacionado = totalCargosEdoRelacionado - totalAbonosEdoRelacionado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))-1

                    if(c.saldo > 1 ){//&&  diffDays > plazo
                      saldoVencidoRelacionado = saldoVencidoRelacionado + c.cargo}
                      return (
                        // <tr style={{backgroundColor:"#ed8c8c"}}>
                          <tr
                          style={
                            c.saldo > 1
                              ? { backgroundColor: "#ed8c8c" }
                              : undefined
                          }//&&  diffDays > plazo
                        >
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                c.cargo - c.abono
                              )}
                          </td>

                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTablaRelacionado
                              )}
                          </td>
                        </tr>
                      )
                  }
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Anterior
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoRelacionado)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoRelacionado)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Vencido
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Actual
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleRelacionado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
        <ModalHeader toggle={toggleMail2}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOC}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail3} toggle={toggleMail3}>
        <ModalHeader toggle={toggleMail3}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOCAgrupado}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail4} toggle={toggleMail4}>
        <ModalHeader toggle={toggleMail4}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOCRelacionado}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalWhatsAppAgrupado} toggle={toggleWhatsAppAgrupado}>
        <ModalHeader toggle={toggleWhatsAppAgrupado}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={telefono}
            required
            onChange={(e) => {
              setTelefono(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={PDFOCAgrupadoWhatsApp}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalWhatsApp} toggle={toggleWhatsApp}>
        <ModalHeader toggle={toggleWhatsApp}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={telefono}
            required
            onChange={(e) => {
              setTelefono(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={PDFOCWhatsApp}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default EstadoCuenta;
