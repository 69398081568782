import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";



function GraficaVentasEstado() {
  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [info, setInfo] = useState([]);
  const [graficas, setGraficas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  useEffect(()=>{

    axios
    .get(`${URL_GRAFICAS}/ventaEstado/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data;

      setInfo(allGraficas);

      let unicosArea = allGraficas.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArea === value.idArea)
      );
      setAreas(unicosArea);


      setCargando(false)
      Toast.close()
    })
    .catch((err) => {
      console.log(err);
    });
    

  },[])
  
  function BuscaInfo(){
    setCargando(true)
    setInfo([])
    setSelectedArea("")

    axios
    .get(`${URL_GRAFICAS}/ventaEstado/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data;
      setInfo(allGraficas);
    

      let unicosArea = allGraficas.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArea === value.idArea)
      );
      setAreas(unicosArea);

  
      setCargando(false)
      Toast.close()
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useMemo(() => {

    let temp = info
    if (selectedArea) {
      temp = temp.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }
    let agrupadoEstado = temp.reduce(function (groups, item) {
      const val = item["Estado"];
      groups[val] = groups[val] || {
        Estado: item.Estado,
        Importe: 0,
      };
      groups[val].Importe += item.Importe;
      groups[val].Estado = item.Estado;
      groups[val].Area = item.Area;
      groups[val].idArea = item.idArea;
      return groups;
    }, []);
    
    let data = Object.values(agrupadoEstado).sort((a, b) =>
    a.Importe < b.Importe ? 1 : -1
  );
    setGraficas(data)
    
  }, [info,selectedArea])


  useLayoutEffect(() => {

    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{Estado} {value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "Importe",
        categoryField: "Estado",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });



    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficas);
    series.appear(1000, 100);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "Estado",
      })
    );

    legend.data.setAll(series.dataItems);
    


    return () => {
      root.dispose();
    };
  }, [graficas]);

  return (
    <>
    <Header />
    <br />
    <br />
    {/* {user.menu_disponible ? ( */}
      <div className="card container col-12">
      <Row>
        <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
        </Row>
        <h3 align="center">Ventas por Estado</h3>
    <div className="container col-12">
    <Row>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
                setCargando(true)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
                setCargando(true)
              }}
            />
          </Col>
          <Col md={2}>
              <Label className="mr-sm-2">Almacen</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {areas
                  .sort((a, b) => (a.Area > b.Area ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArea}>{a.Area}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        </div>
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
            </div>
      {/* ) : undefined}  */}
  </>
  );
}
export default GraficaVentasEstado;
