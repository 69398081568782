import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_ventas ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
              {user.grupos ? (
                <Button
                  href="/DepartamentosClientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-store fa-7x"></i>
                  <br />
                  <br />
                  Departamentos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-store fa-7x"></i>
                  <br />
                  <br />
                  Departamentos
                </Button>
              )}
            </Col>
        <Col md={4} align="center">
              {user.grupos ? (
                <Button
                  href="/Grupos"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-users fa-7x"></i>
                  <br />
                  <br />
                  Grupos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-users fa-7x"></i>
                  <br />
                  <br />
                  Grupos
                </Button>
              )}
            </Col>
        <Col md={4} align="center">
              {user.menu_clientes ? (
                <Button
                  href="/Clientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              )}
            </Col>
            </Row>
          <br />
          <Row>
        <Col md={4} align="center">
          {user.menu_pedidos ?(
            <Button href="/ListadoPedidos" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Pedidos
        </Button> }
          </Col>

      

          <Col md={4} align="center">
          {user.surtidos_create ?(
            <Button href="/ListadoPendienteSurtir" className="botonesMenu" color="success">
              <i class="fas fa-truck-moving fa-7x"></i>
              <br />
              <br />
              Surtir
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-truck-moving fa-7x"></i>
          <br />
          <br />
          Surtir
        </Button> }
          </Col>
          
          <Col md={4} align="center">
          {user.menu_surtidos ?(
            <Button href="/ListadoSurtidos" className="botonesMenu" color="success">
              <i class="fas fa-file-invoice-dollar fa-7x"></i>
              <br />
              <br />
              Notas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-invoice-dollar fa-7x"></i>
          <br />
          <br />
          Notas
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_devoluciones ?(
            <Button href="/ListadoDevoluciones" className="botonesMenu" color="success">
              <i class="fas fa-undo-alt fa-7x"></i>
              <br />
              <br />
              Devoluciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-undo-alt fa-7x"></i>
          <br />
          <br />
          Devoluciones
        </Button> }
          </Col>

          {/* <Col md={4} align="center">
          {user.ventasMenudeo ?(
            <Button href="/VentasMenudeoCreate" className="botonesMenu" color="success">
              <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Ventas Menudeo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-receipt fa-7x"></i>
          <br />
          <br />
          Ventas Menudeo
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.ventasMenudeo ?(
            <Button href="/ListadoVentasMenudeo" className="botonesMenu" color="success">
              <i class="fas fa-box-open fa-7x"></i>
              <br />
              <br />
              Reporte Menudeo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-box-open fa-7x"></i>
          <br />
          <br />
          Reporte Menudeo
        </Button> }
          </Col> 
         </Row>
          <br />
          <Row> */}
        <Col md={4} align="center">
          {user.analisisPedidos ?(
            <Button href="/AnalisisArticulosPedidos" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Analisis Pedidos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Analisis Pedidos
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.pedidosCliente ?(
            <Button href="/ListadoPedidosCliente" className="botonesMenu" color="success">
              <i class="fas fa-shopping-basket fa-7x"></i>
              <br />
              <br />
              Pedidos <br /> Cliente
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-shopping-basket fa-7x"></i>
          <br />
          <br />
          Pedidos <br /> Cliente
        </Button> }
          </Col>

        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
            {user.ultimoPrecio ?(
              <Button
                href="/ReporteUltimoPrecio"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Cliente
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.ultimoPrecio ?(
              <Button
                href="/ReporteUltimoPrecioArticulo"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-tshirt fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Articulo
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Articulo
          </Button> }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
