import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function AbonosProveedoresCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ABONOS_PROVEEDORES = process.env.REACT_APP_URL_ABONOS_PROVEEDORES;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedProveedorNombre, setSelectedProveedorNombre] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [concepto, setConcepto] = useState("");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [importe, setImporte] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [selectedProveedor]);


  const savePago = (event) => {
    event.preventDefault();
    setValidaBoton(false);
        axios.post(
          URL_ABONOS_PROVEEDORES,
          {
            fecha,
            importe,
            proveedores: selectedProveedor,
            concepto,
            bancos: selectedBanco,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then((data) => {
        // PDFOC()
        axios
        .post(
        `${URL_MOVIMIENTOS_BANCOS}`,
        {
            fecha,
            importe: -importe,
            tipo:"Egreso",
            bancos: selectedBanco,
            abonosProveedores:data.data._id,
            moneda,
            tipoCambio:1
        },
        {
            headers: {
            Authorization: `Bearer: ${localStorage.getItem(
                "app_token"
            )}`,
            },
        }
        ).then(async() => {

          await axios
          .get(`${URL_ESTADO_CUENTA}CXP/${selectedProveedor}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((response) => {
            let movimientos = response.data;
      
          let saldoParcial = 0;
          let totalCargos = 0;
          let totalAbonos = 0;
      
          const data = movimientos.map((a) => {
              totalCargos = totalCargos + a.cargo;
              totalAbonos = totalAbonos + a.abono;
              saldoParcial = saldoParcial + a.cargo - a.abono;
              return [
                a.fecha,
                a.movimiento,
                a.numero,
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo),
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono),
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
              ];
          });
          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });
          const doc = new jsPDF({compressPdf: true});
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 150, 5, 33, 10);
          var img2 = new Image();
          img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img2, "png", 150, 25, 35, 15);
          // doc.setFontSize(14)
          doc.text(`Estado de Cuenta`, 15, 30);
          doc.text(`Proveedor ${selectedProveedorNombre}`, 15, 37);
          doc.autoTable({
            head: [
              [
                "Fecha",
                "Movimiento",
                "Numero",
                "Cargos",
                "Abonos",
                "Saldo",
              ],
            ],
            body: dataPDFLimpia,
            startY: 45,
            foot: [
              [
                "",
                "",
                "",
                {
                  content: "Cargos del Periodo",
                  colSpan: 2,
                  styles: { halign: "left" },
                },
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCargos),
              ],
              [
                "",
                "",
                "",
                {
                  content: "Abonos del Periodo",
                  colSpan: 2,
                  styles: { halign: "left" },
                },
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalAbonos),
              ],
              [
                "",
                "",
                "",
                { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
                new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
              ],
            ],
            showFoot: "lastPage",
          });
          var attW = doc.output("arraybuffer");
          let params = new URLSearchParams({
            message:`Hola, se ha realizado un nuevo pago a proveedor. Aquí te enviamos tu estado de cuenta actualizado`,
              fileName:`EstadoCuenta.pdf`
          })
          axios
      .post(
        `${URL_WHATSAPP}SendPDFAdmin?${params.toString()}`,
        attW,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem(
              "app_token"
            )}`,
            "Content-Type": "application/octet-stream",
          },
        }
      )
      
        })
        .catch((err) => {
          console.log(err);
        });

          axios.post(
            URL_LOGS,
            {
              tipo: "Crear Pago",
              detalle: `Fecha: ${fecha} / Proveedor: ${selectedProveedor} / Importe: ${importe} `,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
          Swal.fire("Good job!", "Creado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  
  const options = proveedores.map((option) => {
    const junta = option.nombre_comercial;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.pagoProveedores ? (
        <div className="card container col-sm-8">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Label className="mr-sm-2">Proveedor</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedProveedor) => {
                      if (selectedProveedor) {
                        setValue(selectedProveedor);
                        setSelectedProveedor(selectedProveedor._id);
                        setSelectedProveedorNombre(
                          selectedProveedor.nombre_comercial
                        );
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>

                <Col md={3}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
                </Row>
                <Row>
                <Col md={4}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                    <option value="Descuento">Descuento</option>
                  </Input>
                </Col>

                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={importe}
                        onChange={(e) => setImporte(e.target.value)}
                    />
                  </Col>

               
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonosProveedores"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosProveedoresCreate;
