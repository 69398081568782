import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesInventarios() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
          <Col md={4} align="center">
          {user.inventarios ?(
            <Button href="/ListadoInventarios" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/MenuDisponibles" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponibles
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponibles
        </Button> }
          </Col>
          
          

          <Col md={4} align="center">
          {user.seguimiento_inventarios ?(
            <Button href="/ListadoTracking" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Tracking
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Tracking
        </Button> }
          </Col>

          </Row>
          <br />
        <Row>
          <Col md={4} align="center">
          {user.seguimiento_inventarios ?(
            <Button href="/ListadoKardex" className="botonesMenu" color="success">
              <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Kardex
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-clipboard-list fa-7x"></i>
          <br />
          <br />
          Kardex
        </Button> }
          </Col>
                


          <Col md={4} align="center">
          {user.ubicaciones ?(
            <Button href="/ListadoUbicaciones" className="botonesMenu" color="success">
              <i class="fas fa-thumbtack fa-7x"></i>
              <br />
              <br />
              Ubicaciones por Articulo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-thumbtack fa-7x"></i>
          <br />
          <br />
          Ubicaciones por Articulo
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.ubicaciones ?(
            <Button href="/ListadoUbicaciones2" className="botonesMenu" color="success">
              <i class="fas fa-map-marked-alt fa-7x"></i>
              <br />
              <br />
              Ubicaciones
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-map-marked-alt fa-7x"></i>
          <br />
          <br />
          Ubicaciones
        </Button> }
          </Col>
          
        </Row>
        <br />
        <Row>

        <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoTraspasosSolicitud" className="botonesMenu" color="success">
              <i class="fas fa-file-export fa-6x"></i>
              <br />
              <br />
              Solicitud de Traspaso
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-file-export fa-6x"></i>
          <br />
          <br />
          Solicitud de Traspaso
        </Button> }
          </Col>

          

          <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoPendienteTraspasar" className="botonesMenu" color="success">
              <i class="fas fa-truck-moving fa-7x"></i>
              <br />
              <br />
              Pend Traspasar
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-truck-moving fa-7x"></i>
          <br />
          <br />
          Pend Traspasar
        </Button> }
          </Col>

          

        <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoTraspasosSalida" className="botonesMenu" color="success">
              <i class="fas fa-sign-out-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Salida
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-out-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Salida
        </Button> }
          </Col>

</Row>
<br />
<Row>
          <Col md={4} align="center">
          {user.menu_traspasos_entrada ?(
            <Button href="/ListadoTraspasosEntrada" className="botonesMenu" color="success">
              <i class="fas fa-sign-in-alt fa-6x"></i>
              <br />
              <br />
              Traspasos Entrada
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-in-alt fa-6x"></i>
          <br />
          <br />
          Traspasos Entrada
        </Button> }
          </Col>


        <Col md={4} align="center">
          {user.menu_traspasos_entrada ?(
            <Button href="/ListadoMuestrasEntrada" className="botonesMenu" color="success">
              <i class="fas fa-arrow-down fa-6x"></i>
              <br />
              <br />
              Muestras Entrada
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-arrow-down fa-6x"></i>
          <br />
          <br />
          Muestras Entrada
        </Button> }
          </Col>

          

        <Col md={4} align="center">
          {user.menu_traspasos_salida ?(
            <Button href="/ListadoMuestrasSalida" className="botonesMenu" color="success">
              <i class="fas fa-arrow-up fa-6x"></i>
              <br />
              <br />
              Muestras Salida
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-arrow-up fa-6x"></i>
          <br />
          <br />
          Muestras Salida
        </Button> }
          </Col>

        </Row>
        <br/>
        <Row>
          
          <Col md={4} align="center">
          {user.ajustesInventario ?(
            <Button href="/ListadoAjustesInventario" className="botonesMenu" color="success">
              <i class="fas fa-plus fa-6x"></i>
              <br />
              <br />
              Ajuste Inventario
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-plus fa-6x"></i>
          <br />
          <br />
          Ajuste Inventario
        </Button> }
          </Col>
        <Col md={4} align="center">
            {user.ultimoPrecio ?(
              <Button
                href="/ReporteUltimoPrecio"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Cliente
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Cliente
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.ultimoPrecio ?(
              <Button
                href="/ReporteUltimoPrecioArticulo"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Ultimo Precio Articulo
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Ultimo Precio Articulo
          </Button> }
            </Col>
        </Row>
        <br />
        <Row>
        <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoStockBajo" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Stock Bajo
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Stock Bajo
        </Button> }
          </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesInventarios;
