import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";

function CuadrarInventario() {
  const { user } = useContext(AuthContext);
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;



  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [existenMovs, setExistenMovs] = useState(true);



  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 5000;

  useEffect(() => {


    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  function BuscaArea(event) {
    setSelectedArea(event.target.value);
    setComments([]);


    // Jala Movimientos
    axios
      .get(`${URL_SEGUIMIENTOS}CuadrarInventario/${event.target.value}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMovs = response.data;
        setComments(allMovs);

        console.log("hola",allMovs);
        if(allMovs.length == 0){
          setExistenMovs(false)
        } else{
          setExistenMovs(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

console.log("hola", comments)
  function excel() {
    const dataExcel = comments.map((a) => {
      if(a.diferencia != 0){
        return {
          idInv: a.idInv,
          idArticulo: a.idArticulo,
          NombreArticulo: a.nombreArticulo,
          Ano: a.year,
          // idArea: a.idArea,
          // NombreArea: a.nombreArea,
          Cantidad: a.cantidad,
          Inventario: a.inventario,
          Diferencia: a.diferencia
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoKardex";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoKardex",
        sheetFilter: ["idInv", "idArticulo", "Ano", "NombreArticulo", "Cantidad", "Inventario", "Diferencia"],
        sheetHeader: ["idInv", "idArticulo", "Ano", "NombreArticulo", "Cantidad", "Inventario", "Diferencia"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }


  const headers = [
    { name: "idInv", field: "idInv", sortable: true },
    { name: "idArticulo", field: "idArticulo", sortable: true },
    { name: "Nombre Articulo", field: "nombreArticulo", sortable: true },
    // { name: "idArea", field: "idArea", sortable: true },
    // { name: "Nombre Area", field: "nombreArea", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Inventario", field: "inventario", sortable: true },
    { name: "Diferencia", field: "diferencia", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;


    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "cantidad" &&
      sorting.field != "diferencia"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
        sorting.field == "cantidad" ||
        sorting.field == "diferencia")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
        sorting.field == "cantidad" ||
        sorting.field == "diferencia")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [comments, currentPage, search, sorting]);

  let existencia = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.seguimiento_inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>

            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>


          <h3 align="center">Cuadrar Inventario</h3>
          <div className="row">
            <div className="col-md-2">
              <Label>Almacen</Label>
            </div>

 
          </div>
          <div className="row">
            <div className="col-md-2">
              <Input
                bsSize="sm"
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  BuscaArea(e);
                }}
              >
                <option value="0">Selecciona</option>
                {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                    })}
              </Input>
            </div>


            <div className="col-md-6 d-flex flex-row-reverse" id="logoutBoton">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />

          {/* Tabla Movimientos */}
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />

          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />

            <tbody>
              { existenMovs ? (
              commentsData.map((a) => {
                // if(a.diferencia != 0){
                return (
                  <tr>
                    <td>{a.idInv}</td>
                    <td>{a.idArticulo}</td>
                    <td>{a.nombreArticulo}</td>
                    {/* <td>{a.idArea}</td>
                    <td>{a.nombreArea}</td> */}
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.inventario)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.diferencia)}</td>
                  </tr>
                );
              // }
              })): (
                <tr>
                  <td>No se encontraron Movimientos...</td>
                  <td></td>
                </tr>
                )}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}

      {loader}
    </>
  );
}

export default CuadrarInventario;
